@use '@angular/material' as mat;

@mixin x-overlay-extensions($theme-config) {
  $theme: map-get($theme-config, theme);

  .cdk-overlay-backdrop-showing {
    background: rgba(black, 0.2);
  }
  .cdk-overlay-pane {
    .x-overlay-content {
      border-radius: 0 0 5px 5px;
      background-color: white;
      box-shadow: $drop-shadow;
    }

    &.accent {
      x-triangle {
        background-color: mat.get-color-from-palette(map-get($theme, accent), 100);
      }
      x-overlay-content {
        background-color: mat.get-color-from-palette(map-get($theme, accent), 100);
      }
    }

    &.fullwidth {
      width: 100%;
      left: 0 !important;
      right: 0;
      border-radius: 0;
    }

    &.fullscreen-dialog {
      width: 100% !important;
      max-width: 100vw !important;
      height: 100% !important;
      max-height: 100vh !important;
      inset: 0 !important;
      border-radius: 0;
    }

    .mat-mdc-dialog-container {
      padding: 0;
    }

    @include media-breakpoint-down(lg) {
      &.fullscreen-mobile {
        height: calc(100% - 45px);
        width: 100%;
        left: 0 !important;
        right: 0;
        border-radius: 0;
        .x-overlay-content {
          border-radius: 0;
        }
      }

      &.fullwidth-mobile {
        width: 100%;
        left: 0 !important;
        right: 0;
        border-radius: 0;
      }

      // dialogs
      &.fullscreen-dialog-mobile {
        max-width: 100vw !important;
        width: 100% !important;
        height: 100vh !important;
        top: 0 !important;
        right: auto !important;
      }

      &.fullwidth-dialog-mobile {
        max-width: 100vw !important;
        width: 100% !important;
      }
    }
  }
}
