@use '@angular/material' as mat;
@mixin x-my-account-menu-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-my-account-menu {
    mat-nav-list {
      padding-bottom: 0;
    }

    .actions {
      border-top: 1px solid map-get($custom-colors, borders);
    }

    .mat-mdc-list-base .mat-mdc-list-item {
      height: auto;
      padding: 0;
    }

    .mdc-list-item:hover::before {
      display: none;
    }

    .mat-mdc-nav-list a {
      padding: 5px 0;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}
