@use '@angular/material' as mat;

@mixin x-shop-pill-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-shop-pill {
    background: mat.get-color-from-palette(map-get($theme, accent), 300);
    padding: 5px 8px;
    font-weight: 400;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;

    &.success {
      background: get-custom-color($theme-config, success);
    }

    &.warn {
      background: mat.get-color-from-palette(map-get($theme, warn), 50);
    }
  }
}
