@use '@angular/material' as mat;

@mixin x-order-item-delivery-note-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-order-item-delivery-note {
    display: flex;
    justify-content: flex-start;
    .note {
      @include mat.typography-level($typography, caption);
      background-color: map-get($custom-colors, success);
      color: lighten(map-get($custom-colors, success), 80%);
      padding: 5px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-flex;
      width: 100%;
      border-radius: 3px;
    }
  }
}
