@use '@angular/material' as mat;
@mixin x-button-custom-sizes($theme-config) {
  $typography: map-get($theme-config, typography);
  .mat-mdc-button-base {
    &.size-small {
      padding: 0 8px;
      font-size: 12px;
      height: 32px;
      line-height: 32px;
      min-width: auto;

      .mdc-button__label {
        line-height: 24px !important;
      }
    }

    &.size-normal {
      padding: 0 10px;
      font-size: 13px;
      height: 40px;
      line-height: 40px;
      min-width: auto;

      .mdc-button__label {
        line-height: 32px !important;
      }
    }

    &.size-medium {
      @include mat.typography-level($typography, button);
      padding: 0 12px;
      height: 48px;
      min-width: auto;
    }

    &.size-full {
      width: 100%;
    }
  }
}
