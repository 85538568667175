@use '../../../../../../../../node_modules/@angular/material/index' as mat;

@mixin x-region-button-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  .x-region-button {
    .mat-mdc-button-base {
      background: map-get($custom-colors, region-button-not-selected-bg);
      color: map-get($custom-colors, region-button-not-selected-color);
      border-radius: 13px;
      .mdc-button__label {
        justify-content: flex-start;
        width: 100%;
      }

      &.selected {
        background: map-get($custom-colors, region-button-selected-bg);
        color: map-get($custom-colors, region-button-selected-color);
      }

      .mat-mdc-chip {
        background: darken(map-get($custom-colors, success), 10%);
        color: map-get($custom-colors, region-button-selected-color);
        line-height: normal;
        margin-left: 10px;
        height: 18px;
      }

      .street-label {
        display: inline-block;
        @include mat.typography-level($typography, 'display-2');
        @include media-breakpoint-up(lg) {
          max-width: 100px;
          @include mat.typography-level($typography, 'display-1');
        }
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @include media-breakpoint-down(lg) {
      .mat-mdc-button-base {
        border-radius: 0;
        @include mat.typography-level($typography, caption);
      }
    }
  }
}
