@use '@angular/material' as mat;

/*default styles*/
@import 'material-extensions';
@import 'bootstrap';
@import 'print-view';
@import 'colors';
@import 'theme-mixins';
@import 'browser';
@import 'typography';
@import 'node_modules/cloudinary-video-player/dist/cld-video-player.min.css';
@import 'swiper/swiper';

/*pages*/
@import '../app/auth-flow/auth-flow.theme';
@import '../app/channel/channel.theme';
@import '../app/checkout/checkout.theme';
@import '../app/my-account/my-account.theme';
@import '../app/product-view/product-view.theme';
@import '../app/product-listing/product-listing.theme';
@import '../app/taxon/taxon-page.theme';
@import '../app/membership/membership.theme.scss';
@import '../app/subscription/subscription.theme';
@import '../app/subscriber-checkout/subscriber-checkout.theme';

/*core*/
@import '../app/core/content/shop-content-dynamic.theme';
@import '../app/core/core.theme';

/*libs*/
@import 'libs/content/src/render/content-render.theme';
@import 'libs/common/src/form/components/address-autocomplete/address-autocomplete.theme';
@import 'libs/common/src/media-thumbnail/media-thumbnail.theme';
@import 'libs/common/src/media/cloudinary-player/cloudinary-player.theme';
@import 'libs/common/src/loader/loader.theme';
@import 'libs/common/src/swiper/swiper.theme';
@import 'libs/common/src/swiper-gallery/swiper-gallery.theme';
@import 'libs/common/src/ads/components/ad/ad.theme';
@import '/libs/common/src/media/cloudinary-player/cloudinary-player.theme';
@import '/libs/common/src/media/html5-player/html5-player.theme';

/*flavour*/

@mixin create-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  @include mat.core();
  @include mat.core-theme($theme);
  @include mat.all-component-themes($theme);
  @include mat.all-component-typographies($typography);
  @include bootstrap-config();
  @include print-view($theme, $typography);
  @include browser($theme-config);
  @include x-typography($theme-config);

  /*libs*/
  @include x-content-render-theme($theme-config);
  @include x-address-autocomplete-theme($theme, $typography);
  @include x-media-thumbnail-theme($theme-config);
  @include x-cloudinary-player-theme($theme-config);
  @include x-html5-theme($theme-config);
  @include x-loader-theme($theme-config);
  @include x-swiper-theme($theme-config);
  @include x-swiper-gallery-theme($theme-config);

  .shop {
    @include x-colors($theme-config);
    @include x-material-component-extensions($theme-config);
    @include x-auth-flow-theme($theme-config);
    @include x-channel-theme($theme-config);
    @include x-checkout-theme($theme-config);
    @include x-core-theme($theme-config);
    @include x-product-view-theme($theme-config);
    @include x-my-account-theme($theme-config);
    @include x-product-listing-theme($theme-config);
    @include x-taxon-page-theme($theme-config);
    @include x-membership-theme($theme-config);
    @include x-subscription-theme($theme-config);
    @include x-subscriber-checkout-theme($theme-config);
    @include x-shop-content-dynamic-theme($theme-config);
    @include x-address-autocomplete-theme($theme, $typography);
    @include x-ad($theme-config);
  }
}
