@use '@angular/material' as mat;

@mixin x-product-list-item-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  .x-product-list-item {
    .item {
      display: flex;
      gap: 10px;

      .media {
        flex-shrink: 0;
        position: relative;

        x-html5-player {
          height: 100%;
        }
      }

      .body-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        container-type: inline-size;

        .footer {
          display: flex;
          gap: 12px;
        }

        .actions {
          display: flex;
          width: 250px;

          @include media-breakpoint-up(lg) {
            zoom: 90%;
          }

          @container (width < 250px) {
            width: 100%;
          }
        }
      }
    }
  }
}
