@use '@angular/material' as mat;

@mixin x-express-checkout-success($theme) {
  .x-express-checkout-success {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(lg) {
      position: fixed;
      z-index: 3;
      inset: 0;
    }
    .actions {
      padding: 16px;
      text-align: center;
      gap: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
