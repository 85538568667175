@use '@angular/material' as mat;
@mixin x-quantity-control-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-quantity-control {
    .quantity-control {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      border: 1px solid map-get($custom-colors, borders);
      background-color: map-get($custom-colors, bg-light);

      .control {
        font-size: 1.5rem;
      }

      .quantity {
        width: 30px;
        text-align: center;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      button {
        height: 100%;
        min-width: auto;
        letter-spacing: normal;
        text-align: center;
        padding: 0;
        line-height: normal;
      }

      span,
      button {
        width: 30px;
      }

      @include media-breakpoint-down(lg) {
        span,
        button {
          width: 30px;
        }
      }
    }
  }
}
