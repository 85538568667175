@use '@angular/material' as mat;
@mixin x-brand-icon-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $primary: map-get($theme, 'primary');
  $foreground: map-get($theme, 'foreground');

  .x-brand-icon {
    display: inline-block;
    svg {
      width: 100%;
      height: 100%;
      min-width: 10px;
      min-height: 10px;
    }

    &.color-light {
      fill: mat.get-color-from-palette($foreground, 'light-icon');
    }
    &.color-primary {
      fill: mat.get-color-from-palette($primary, 500);
    }
  }
}
