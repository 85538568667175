@use '@angular/material' as mat;

@mixin x-html-container-theme($theme-config) {
  .x-html-container {
    .x-markdown-view {
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        text-align: center;
      }
      .div-center {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
      }
      .payment-methods {
        display: flex;
        justify-content: center;
        gap: 10px;
      }
      .text-footer {
        display: flex;
        justify-content: center;
        gap: 50px;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          text-align: center;
          gap: 10px;
        }
      }
      .img-small {
        height: 1em;
        margin-right: 4px;
        opacity: 0.6;
      }
      .img-large {
        height: 110px;
        margin-bottom: 48px;
      }
      .vid-embed {
        width: 1000px;
        height: 500px;
        @include media-breakpoint-down(md) {
          width: 380px;
          height: 700px;
        }
      }
    }
  }
}
