@use '@angular/material' as mat;
@mixin x-main-menu-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  .x-main-menu {
    nav {
      display: flex;
      justify-content: center;
      width: 100%;
      background: map-get($custom-colors, nav-bg);
      border-bottom: 1px solid map-get($custom-colors, nav-border-bottom);
      .mat-mdc-tab-nav-bar {
        z-index: 2;
        /*disable hover*/
        .mat-mdc-tab-link .mdc-tab__ripple::before {
          display: none;
        }

        .mat-mdc-tab-link {
          padding: 0px 12px;
        }

        &.mat-mdc-tab-header {
          flex-shrink: 1;
        }

        .mat-mdc-tab-header-pagination {
          border-right: 1px solid map-get($custom-colors, nav-border-bottom);
          border-left: 1px solid map-get($custom-colors, nav-border-bottom);
        }

        .mat-mdc-tab-links {
          justify-content: center;
          a {
            transition:
              color ease-out 300ms,
              background 300ms ease-out;
            color: map-get($custom-colors, body-color-2);

            &.mdc-tab--active {
              color: mat.get-color-from-palette(map-get($theme, primary), 500);
            }
          }
        }
      }
      .nav-actions {
        display: flex;
        position: relative;
        z-index: 2;
      }
    }

    .sub-menu-wrap {
      background-color: white;
      border-bottom: 1px solid map-get($custom-colors, borders);

      .sub-menu a {
        text-transform: uppercase;
        color: map-get($custom-colors, body-color-2);
        @include mat.typography-level($typography, subtitle-2);

        span {
          position: relative;
          transition: color 300ms ease-out;
          &:after {
            content: '';
            height: 1px;
            width: 100%;
            opacity: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: opacity 300ms ease-out;
            background: mat.get-color-from-palette(map-get($theme, primary), 500);
          }
        }
      }

      .sub-menu .sub-menu a {
        @include mat.typography-level($typography, display-2);
        color: map-get($custom-colors, body-color-2);
        text-transform: none;
      }

      .sub-menu .sub-menu a {
        &:hover,
        &.active {
          color: mat.get-color-from-palette(map-get($theme, primary), 500);
          span:after {
            opacity: 1;
          }
        }
      }
    }
  }
}
