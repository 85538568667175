@use '@angular/material' as mat;

@mixin x-button-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $foreground: map-get($theme, 'foreground');
  $background: map-get($theme, 'background');

  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');

  $buttonColors: (
    default: currentColor,
    primary: mat.get-color-from-palette($primary),
    accent: mat.get-color-from-palette($accent),
    warn: mat.get-color-from-palette($warn),
  );

  $outlineButtonForegroundColors: (
    default: $primary,
    primary: white,
    accent: white,
    warn: white,
  );

  $linkButtonAlignments: (
    start: left,
    center: center,
    end: right,
  );

  .x-button {
    text-decoration: none;
    white-space: nowrap;
    display: inline-flex;
    flex-direction: column;
    line-height: 2.5;
    font-size: 13px;
    position: relative;
    color: inherit;
    text-transform: uppercase;

    cursor: pointer;

    .link {
      @include mat.typography-level($typography, 'button');
      padding-bottom: 8px;
      .mat-button-focus-overlay {
        background: transparent;
      }
      @each $name, $alignment in $linkButtonAlignments {
        &.align-#{$name} {
          &:after {
            content: '';
            position: absolute;
            width: 30%;
            height: 0.5px;
            background: currentColor;
            transition-duration: 0.5s;
            // transition: all 512ms ease;
            bottom: 0;

            @if $alignment == left {
              left: 0;
            }
            @if $alignment == right {
              right: 0;
            }
            @if $alignment == center {
              left: calc(50% - 17px);
            }
          }
          &:hover {
            &:after {
              width: 100%;
              @if $alignment == center {
                left: 0;
                // transform: translateX(0%);
              }
            }
          }
        }
      }

      @each $name, $color in $buttonColors {
        &.color-#{$name} {
          color: $color;
          &:after {
            background: $color;
          }
        }
      }
    }

    .outline {
      border-radius: $buttons-border-radius;
      border: 1px solid currentColor;
      padding: 18px 22px;

      @include mat.typography-level($typography, 'button');

      @each $name, $color in $buttonColors {
        &.color-#{$name} {
          color: $color;
          border-color: $color;
        }
      }
    }

    .fill {
      border-radius: $buttons-border-radius;
      padding: 18px 22px;
      transition: all 256ms ease;

      @include mat.typography-level($typography, 'button');

      @each $name, $color in $buttonColors {
        &.color-#{$name} {
          background-color: $color;
          color: white;
        }
        &.color-default {
          background-color: white;
          color: black;
        }
      }
    }

    .brand {
      border-radius: 16px;
      background-color: mat.get-color-from-palette($primary);
      color: white;
      padding: 13px 32px;
      font-size: 16px;
      text-align: center;

      img {
        width: 80px;
        height: 80px;
        margin: auto;
      }

      @each $name, $color in $buttonColors {
        &.#{$name} {
          background-color: $color;
        }
      }
    }
  }
}
