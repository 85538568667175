@use '@angular/material' as mat;

@mixin x-wishlist-action-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .x-wishlist-action {
    button {
      fa-icon.heart {
        path {
          fill: mat.get-color-from-palette(map-get($theme, accent), 900);
          stroke: mat.get-color-from-palette(map-get($theme, accent), 900);
          stroke-width: 3px;
          fill-opacity: 0;
          transition:
            fill-opacity 300ms ease-in-out,
            stroke 300ms ease-in-out;
        }
      }

      &.active {
        fa-icon.heart {
          path {
            fill-opacity: 1;
          }
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          fa-icon.heart {
            path {
              fill-opacity: 1;
            }
          }
        }
      }
    }
  }
}
