@use '@angular/material' as mat;

@mixin x-mat-nav-list-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .mat-mdc-list-base {
    padding-top: 0;
  }

  .mat-mdc-list-base .mat-mdc-list-item {
    font-family: 'Nunito Sans', sans-serif;
  }

  .mat-list-option:hover,
  .mat-list-option:focus,
  .mat-nav-list .mat-mdc-list-item:hover,
  .mat-nav-list .mat-list-item:focus {
    background: none;
  }

  .mat-mdc-list-item {
    a {
      display: flex;
      align-items: center;
      width: 100%;
      @include mat.typography-level($typography, body-2);

      &:hover,
      &.is-active {
        background: none;
        color: mat.get-color-from-palette(map-get($theme, primary), 500);
      }
    }
  }
}
