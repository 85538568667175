@use '@angular/material' as mat;

@mixin x-vouchers-page-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-vouchers-page {
    text-align: center;
    .voucher {
      display: grid;
      align-items: center;
      margin: 5px;
      padding: 10px;
      background: mat.get-color-from-palette(map-get($theme, accent), 100);
      gap: 5px;
      grid-auto-columns: 1fr 2fr;

      @include media-breakpoint-down(lg) {
        grid-auto-columns: 2fr 1fr;
      }

      grid-auto-flow: column;

      div {
        text-align: left;
      }
      .btn {
        margin-left: auto;
      }
    }
  }
}
