@use '@angular/material' as mat;
@mixin x-select-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $theme: map-get($theme-config, theme);

  .x-select {
    .select-wrapper {
      display: flex;
      align-items: center;
      border: solid 1px mat.get-color-from-palette(map-get($theme, accent), 300);
      padding: 10px;
      border-radius: 20px;
      width: fit-content;

      .picker-label {
        display: flex;
        align-items: center;
      }

      .pointer {
        cursor: pointer;
        white-space: nowrap;
      }
      .mat-mdc-text-field-wrapper {
        padding-bottom: 0;
        background: none;
      }

      .mat-mdc-form-field-infix {
        width: auto;
        border-top: none;
        padding: 0;
        min-height: auto;
      }
      .mat-mdc-form-field-flex {
        padding: 0;
        display: flex;
        align-items: center;

        .mat-mdc-select {
          display: flex;
          .mat-mdc-select-trigger {
            height: auto;
            gap: 20px;
            .mat-mdc-select-arrow-wrapper {
              margin: 0 6px;
            }
          }
        }
      }

      .mdc-line-ripple,
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      .mat-mdc-form-field-focus-overlay {
        background: none;
      }

      .mat-mdc-select-arrow-wrapper {
        background: map-get($custom-colors, channel-picker-arrow);
      }

      mat-select-trigger {
        display: flex;
        gap: 5px;

        fa-icon {
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .xSelectOption {
    min-height: unset;
    padding: 10px 16px;
  }
}
