@use '@angular/material' as mat;

@mixin x-product-attributes-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-product-attributes {
    border-top: 1px solid map-get($custom-colors, borders);

    mat-accordion {
      gap: 0;
    }
  }
}
