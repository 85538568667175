@use '@angular/material' as mat;

@mixin x-payment-credit-page-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  $primary: map-get($theme, 'accent');

  .x-payment-credit-page {
    .expansion-panel {
      border-top: 1px solid map-get($custom-colors, borders);
      background-color: mat.get-color-from-palette($primary, 100);

      @include media-breakpoint-up(md) {
        border-top: none;
      }
    }

    @include media-breakpoint-down(lg) {
      .payment-accordion {
        .mat-expansion-panel-body {
          padding: 5px;
        }
      }
    }
  }
}
