@use '@angular/material' as mat;

@mixin x-mat-chip-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  //mat-chip

  .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
    margin: unset !important;
    gap: 5px;

    .mdc-evolution-chip {
      margin: unset;
    }
  }

  .mat-mdc-chip:not(.mdc-evolution-chip--disabled) {
    @include mat.typography-level($typography, caption);
    background-color: mat.get-color-from-palette(map-get($theme, accent), 200);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 20px;
    height: auto;
    padding: 5px 8px;
    .mat-mdc-chip-action {
      padding: 0;
      .mat-mdc-chip-action-label {
        color: inherit;
        font: inherit;
      }
    }
  }
}
