@use '@angular/material' as mat;

@mixin x-order-summary-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $warn-color: mat.get-color-from-palette(map-get($theme, warn), 500);
  $typography: map-get($theme-config, typography);

  .x-order-summary {
    color: map-get($custom-colors, body-color-2);

    &.compact {
      .items-wrapper {
        padding: 15px;
      }
    }

    &.expanded {
      header {
        @include order-item-expand-grid;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid get-custom-color($theme-config, 'borders');
        @include get-font($theme-config, body-1);
      }

      .items-wrapper.notice {
        padding: 15px 20px;
        margin: 0 -20px;
      }

      .promo-items {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid get-custom-color($theme-config, 'borders');
      }
    }

    .items-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &.notice {
        background: rgba($warn-color, 0.1);
        .msg,
        .action {
          color: $warn-color;
        }
      }

      &:not(:first-child) {
        border-top: none;
      }

      .items {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      > .action {
        display: flex;
        align-items: center;
        gap: 5px;
        line-height: normal;
        justify-content: space-between;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-order-summary {
      display: block;

      .items-wrapper {
        padding: 10px;

        .items {
          gap: 20px;
          max-width: 100%;
        }
      }
    }
  }
}
