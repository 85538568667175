@mixin x-iframe-pay-dialog-theme($theme-config) {
  .payment-form {
    width: 100%;
    height: 600px;
    position: relative;

    @include media-breakpoint-down(lg) {
      height: 100%;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  .fullscreen-dialog .payment-form {
    height: 100%;
  }
}
