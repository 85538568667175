@mixin x-html5-theme($theme) {
  .x-html5-player {
    display: block;
    width: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
