@use '@angular/material' as mat;
@mixin x-footer-payment-methods-theme($theme-config) {
  .x-footer-payment-methods {
    .payment-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .text-section {
        display: flex;
        justify-content: center;

        span {
          display: flex;
          align-items: center;
        }
      }
      .icon-section {
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;

        .payment-logo {
          height: 1.2em;
        }
      }
    }
    @include media-breakpoint-down(lg) {
    }
  }
}
