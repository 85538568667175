@use '@angular/material' as mat;
@mixin x-button-loading($theme-config) {
  .mat-mdc-button-base {
    &.loading {
      .mdc-button__label {
        opacity: 0;
      }

      x-loader {
        .circle {
          font-size: 2px;
        }
      }
    }
  }
}
