@mixin x-bottom-sheet($theme-config) {
  $theme: map-get($theme-config, theme);

  .address-bottom-sheet {
    overflow: hidden;
    padding: 0;
    max-width: 800px !important;

    mat-bottom-sheet-container {
      &.mat-bottom-sheet-container {
        padding: 0;
        max-height: 95vh;
        max-height: 95dvh;
        height: 95vh;
        height: 95dvh;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .address-bottom-sheet {
      min-height: 100%;
      max-height: 100%;
      max-width: 100%;

      mat-bottom-sheet-container {
        &.mat-bottom-sheet-container {
          max-height: 100vh;
          max-height: 100dvh;
          height: 100vh;
          height: 100dvh;
        }
      }
    }
  }
}
