@use '@angular/material' as mat;

@mixin x-delivery-collect-theme($theme-config) {
  .collect-header {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -20px;
    position: sticky;
    top: 139px;
    @include media-breakpoint-down(lg) {
      top: 127px;
    }
    background: white;
    z-index: 1;
  }
}
