@use '@angular/material' as mat;
@mixin x-address-form-bottom-sheet-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-address-form-bottom-sheet {
    x-bottom-sheet-layout {
      > header,
      > footer {
        padding: 15px;

        .actions {
          button:last-child {
            margin-left: auto;
          }
        }
      }

      > header {
        border-bottom: none;
      }
    }

    /*mat stepper*/
    mat-stepper {
      .mat-horizontal-stepper-wrapper {
        .mat-horizontal-stepper-header-container {
          position: sticky;
          top: 0;
          background: mat.get-color-from-palette(map-get($theme, accent), 200);
          z-index: 1;
          .mat-horizontal-stepper-header {
            height: 60px;
          }
        }

        .mat-horizontal-content-container {
          .mat-horizontal-stepper-content {
            &:last-child {
              height: 100%;
              display: flex;
              flex-direction: column;

              x-address-map {
                height: 100%;
                flex-grow: 1;
              }
            }
          }
        }
      }
    }

    .active-step-1 {
      mat-stepper {
        height: 100%;
        .mat-horizontal-stepper-wrapper {
          height: 100%;
          .mat-horizontal-content-container {
            flex: 1;
            padding: 0;

            @include media-breakpoint-down(lg) {
              .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
                height: 100%;

                x-address-map {
                  height: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-address-form-bottom-sheet {
      x-bottom-sheet-layout {
        > header,
        > footer {
          padding: 10px;
        }
      }

      .addressing {
        display: flex;
        gap: 5px;
        flex-direction: column-reverse;
      }
    }
  }
}
