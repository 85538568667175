@use '@angular/material' as mat;

@mixin x-shop-notification-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-shop-notification {
    .notification {
      display: flex;
      align-items: center;
      .message {
        text-align: center;
        width: 100%;
        margin: 8px 0;
      }
      .exit {
        cursor: pointer;
        display: inline-block;
        padding: 0 10px;
      }

      &.background {
        &-transparent {
          background-color: transparent;
        }
        &-light {
          background-color: map-get($custom-colors, notification-light-bg);
          color: map-get($custom-colors, notification-light-color);
        }
        &-primary {
          background-color: mat.get-color-from-palette(map-get($theme, primary), 500);
          color: white;
        }
        &-accent {
          background-color: mat.get-color-from-palette(map-get($theme, accent), 300);
          color: black;
        }
        &-warn {
          background-color: mat.get-color-from-palette(map-get($theme, warn), 500);
          color: white;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      .notification {
        .message {
          margin-left: 8px;
        }
      }
    }
  }
}
