@use '@angular/material' as mat;

@mixin x-product-options-form-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  @include media-breakpoint-down(lg) {
    .x-product-options-form {
      display: block;

      .mat-button-toggle-group {
        flex-wrap: wrap;
        mat-button-toggle {
          button {
            height: 40px;
            .mat-button-toggle-label-content {
              padding: 0 8px;
            }
          }
        }
      }
    }

    .sticky-action-bar {
      .x-product-options-form {
        .mat-button-toggle-group {
          justify-content: start;
        }
      }
    }
  }
}
