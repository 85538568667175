@use '@angular/material' as mat;

@mixin browser($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  // defaults
  * {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    color: map-get($custom-colors, body-color-1);

    /* responsive font size bounded by rems*/
    font-size: 16px;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  strong {
    font-weight: 500;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  // This gets rid of inconsistent black border around video
  video {
    clip-path: inset(-1px);
  }

  //https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
    font-size: inherit !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
