@use '@angular/material' as mat;
@mixin x-checkout-summary-section-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-checkout-summary-section {
    padding: 15px;
    border: 1px solid mat.get-color-from-palette(map-get($theme, accent), 400);
    display: block;

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .actions {
        display: flex;
        gap: 15px;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    @include media-breakpoint-down(lg) {
      padding: 10px;
    }
  }
}
