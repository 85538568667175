@use '@angular/material' as mat;

@mixin x-address-view-dialog-theme($theme-config) {
  $typography: map-get($theme-config, typography);

  .x-address-view-dialog {
    display: flex;
    flex-direction: column;
    table {
      tr {
        td {
          vertical-align: top;
          @include mat.typography-level($typography, display-2);
        }
        td:first-child {
          white-space: nowrap;
          @include mat.typography-level($typography, display-3);
        }
        td:last-child {
          padding-left: 5px;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      height: 90vh;

      x-address-map {
        height: 100% !important;
      }
    }
  }
}
