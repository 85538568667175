@use '@angular/material' as mat;

@mixin x-add-to-cart-button-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-add-to-cart-button {
    display: flex;
    flex-grow: 1;

    button.disabled {
      pointer-events: none;
    }

    .add-to-cart-button-group {
      display: flex;
      width: 100%;

      .controls {
        display: flex;
        border: 1px solid mat.get-color-from-palette($custom-colors, 'body-color-1');
        color: mat.get-color-from-palette($custom-colors, 'body-color-1');

        .mat-mdc-button-base {
          height: 46px;
          padding: 0 10px;
          color: inherit;
        }

        .control {
          font-size: 26px;
          width: 46px;

          @include media-breakpoint-down(lg) {
            width: 36px;
          }

          &.size-small {
            width: 40px;
          }

          .control-text {
            letter-spacing: normal;
          }
        }

        .quantities {
          display: flex;
          align-items: center;
          font-weight: 300;
        }
      }

      .label {
        display: flex;
        align-items: center;
        justify-content: center;
        @include mat.typography-level($typography, button);

        @include media-breakpoint-down(lg) {
          gap: 0;
        }

        .current-quantity {
          display: flex;
          align-items: center;
          justify-content: end;
          height: 100%;
          max-width: 30px;
          text-align: center;
          line-height: normal;
          padding: 0;
          color: mat.get-color-from-palette(map-get($theme, primary), 500);

          @include media-breakpoint-down(lg) {
            align-self: center;
            width: 50px;
          }
        }

        .label-text {
          display: block;

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }

      .diff-quantity {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0;
        transition: width ease-in-out 300ms;
        overflow: hidden;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        @include mat.typography-level($typography, button);
      }

      .show-quantity-diff {
        .add-button {
          border-radius: 3px 0 0 3px;
        }

        .diff-quantity {
          border: 1px solid mat.get-color-from-palette(map-get($theme, primary), 500);
          width: 46px;
        }
      }

      .with-controls {
        display: flex;
        gap: 10px;
        width: 100%;

        .add-all-button {
          flex-grow: 1;
        }
      }

      .controls-only {
        width: 100%;
        position: relative;

        .controls {
          border: none;

          .control {
            color: white;
            height: 48px;
            flex-shrink: 0;

            &.remove-button {
              border-radius: 4px 0 0 4px;
            }

            &.add-button {
              border-radius: 0 4px 4px 0;
            }
          }

          .label {
            width: fit-content;
            flex-grow: 1;
            gap: 5px;
            text-transform: uppercase;
            padding: 0 10px;
            overflow: hidden;
            height: 48px;
            border: 1px solid mat.get-color-from-palette(map-get($theme, primary), 500);

            @include media-breakpoint-down(lg) {
              gap: 0;
              padding: 0;
            }

            &.loading {
              > * {
                opacity: 0;
              }
            }

            .current-quantity {
              text-align: right;
              @include mat.typography-level($typography, button);

              @include media-breakpoint-down(lg) {
                display: flex;
                text-align: center;
                justify-content: center;
              }
            }
          }
        }
      }

      .no-controls {
        display: flex;
        width: 100%;

        .add-button {
          flex: 1;

          @include media-breakpoint-down(lg) {
            padding: 5px 22px;
            overflow: hidden;

            .mdc-button__label {
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 40px;
              display: block;
            }
          }

          &.show-quantity {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .label {
          &.show {
            @include media-breakpoint-down(lg) {
              border-left: none;
              width: 32px;
            }
          }
        }
      }
    }
  }
}
