@use '@angular/material' as mat;

@mixin x-need-help-bottom-sheet-theme($theme-config) {
  .x-need-help-bottom-sheet {
    .need-help {
      padding: 20px;

      h2,
      .help-time {
        text-align: center;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: flex-start;
      margin: auto;

      .detail {
        display: flex;
        align-items: flex-start;
        gap: 5px;

        fa-icon {
          margin: 2px 8px 0 0;
        }

        .msg {
          text-align: left;
          line-height: normal;
        }
      }
    }
  }

  #robin_tab_container {
    transform: translateX(300px);
    transition: transform 0.3s ease-in-out;

    &.activated {
      transform: translateX(0);
    }
  }
}
