@use '@angular/material' as mat;

@mixin x-taxon-page-theme($theme-config) {
  .x-taxon-page {
    .content:empty {
      display: none;
    }

    .cdk-virtual-scroll-content-wrapper {
      max-width: 100%;
      display: flex;
      flex-direction: column;
    }

    @include media-breakpoint-down(xl) {
      .taxon-row {
        //padding: 15px;
      }
    }
  }
}
