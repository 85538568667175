@use '@angular/material' as mat;
@use 'sass:map';

/*config*/
@import 'typography-config';
@import 'color-palette-config';
@import 'custom-color-config';

/*theme base*/
@import '../../../styles/theme-base';

$color-palette-config: color-palette-config();
$typography: typography-config();

// theme
$theme: mat.define-light-theme(
  (
    color: (
      primary: map-get($color-palette-config, primary),
      accent: map-get($color-palette-config, accent),
      warn: map-get($color-palette-config, warn),
    ),
    density: 0,
  )
);

// custom
$custom-config: (
  colors: custom-color-config($theme),
);

$theme-config: (
  typography: $typography,
  theme: $theme,
  custom: $custom-config,
);

@include create-theme($theme-config);
