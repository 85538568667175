@use '@angular/material' as mat;

@mixin x-action-grid-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');

  .x-action-grid {
    .grid {
      width: 100%;
      display: inline-flex;
      flex-wrap: nowrap;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

    .action-grid-item {
      position: relative;
      display: flex;
      width: 100%;

      justify-content: center;
      align-items: center;
      text-align: center;
      overflow: hidden;

      a:not(.x-button) {
        width: 100%;
        height: 100%;
      }

      .link {
        text-align: center;

        position: absolute;
        text-decoration: none;

        color: white;
        font-weight: bold;
        font-size: 12px;

        .x-button {
          position: relative;
          align-items: center;
          height: 100%;
          justify-content: space-evenly;
        }
      }
    }

    .background {
      &-transparent {
        background-color: transparent;
      }
      &-light {
        background-color: mat.get-color-from-palette($accent, 100);
        color: black;
      }
      &-primary {
        background-color: mat.get-color-from-palette($primary, 100);
        color: black;
      }
      &-accent {
        background-color: mat.get-color-from-palette($accent, 300);
        color: black;
      }
      &-warn {
        background-color: mat.get-color-from-palette($warn, 100);
        color: black;
      }
    }
  }
}
