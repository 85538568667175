@use '@angular/material' as mat;

@mixin x-badge-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .x-badge {
    @include mat.typography-level($typography, display-4);
    background-color: get-custom-color($theme-config, badge-bg);
    width: fit-content;
    color: white;
    border-radius: 16px;
    padding: 4px 9px;
    margin-left: 10px;
  }
}
