@use '@angular/material' as mat;

@mixin x-loader-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $default-color: mat.get-color-from-palette(map-get($theme, primary), 500);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), 500);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), 500);
  $warn-color: mat.get-color-from-palette(map-get($theme, warn), 500);

  .x-loader {
    .dot-windmill {
      background-color: $default-color;
      color: $default-color;
    }

    .dot-windmill::before {
      background-color: $default-color;
      color: $default-color;
    }

    .dot-windmill::after {
      background-color: $default-color;
      color: $default-color;
    }

    .dot-flashing {
      background-color: $default-color;
      color: $default-color;

      &.light {
        background-color: white;
        //color: white !important;
      }
    }

    .circle {
      &.dark {
        border-left: 1.1em solid $default-color;
        border-top: 1.1em solid rgba($default-color, 0.2);
        border-right: 1.1em solid rgba($default-color, 0.2);
        border-bottom: 1.1em solid rgba($default-color, 0.2);
      }

      &.light {
        border-left: 1.1em solid white;
        border-top: 1.1em solid rgba(white, 0.2);
        border-right: 1.1em solid rgba(white, 0.2);
        border-bottom: 1.1em solid rgba(white, 0.2);
      }

      &.primary {
        border-left: 1.1em solid $primary-color;
        border-top: 1.1em solid rgba($primary-color, 0.2);
        border-right: 1.1em solid rgba($primary-color, 0.2);
        border-bottom: 1.1em solid rgba($primary-color, 0.2);
      }

      &.accent {
        border-left: 1.1em solid $accent-color;
        border-top: 1.1em solid rgba($accent-color, 0.2);
        border-right: 1.1em solid rgba($accent-color, 0.2);
        border-bottom: 1.1em solid rgba($accent-color, 0.2);
      }

      &.warn {
        border-left: 1.1em solid $warn-color;
        border-top: 1.1em solid rgba($warn-color, 0.2);
        border-right: 1.1em solid rgba($warn-color, 0.2);
        border-bottom: 1.1em solid rgba($warn-color, 0.2);
      }

      &.small {
        width: 20px;
        height: 20px;
        border-width: 0.7em;
      }
    }
  }
}
