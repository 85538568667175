@use '@angular/material' as mat;

@mixin x-product-price($theme-config) {
  .x-product-price {
    .price {
      @include media-breakpoint-down(lg) {
        font-size: 1.32rem;
      }
    }
  }
}
