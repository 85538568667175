@use '@angular/material' as mat;

@mixin x-order-bag-button-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-order-bag-button {
    @include media-breakpoint-up(lg) {
      margin-left: -3px;
      margin-right: 3px;
    }
    .mdc-icon-button {
      padding-left: 5px;
      padding-right: 5px;

      @include media-breakpoint-up(lg) {
        width: auto;
      }

      fa-icon {
        svg.svg-inline--fa {
          width: 1.04rem;
          height: 1.04rem;
        }
      }

      &.mat-mdc-icon-button {
        .mat-badge-content {
          background: map-get($custom-colors, cart-icon-bg);
          border: 1px solid map-get($custom-colors, cart-icon-border-color);
          color: map-get($custom-colors, cart-icon-color);
          line-height: 14px;
          letter-spacing: 0;
          margin: unset;
          right: auto;
          left: 8px;
        }

        &.no-count {
          .mat-badge-content {
            background: map-get($custom-colors, cart-icon-no-count-bg);
            color: map-get($custom-colors, cart-icon-no-count-color);
          }
        }
      }
    }
  }
}
