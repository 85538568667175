@use '@angular/material' as mat;

@mixin x-search-input($theme-config) {
  .x-search-input {
    width: 100%;
    background: white;
    display: block;
    .search-container {
      .search-form {
        display: flex;
        width: 70%;
        margin: auto;

        .mdc-text-field {
          border-radius: 2px 4px 4px 2px;
        }

        mat-form-field {
          width: 100%;
          .mat-mdc-form-field-flex {
            position: relative;
            overflow: hidden;
            .mat-mdc-form-field-icon-suffix {
              height: 100%;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              padding: 0;

              button {
                border-radius: 0 3px 3px 0;
                height: 100%;
              }
            }

            .mat-mdc-input-element {
              width: calc(100% - 80px);
            }
          }

          x-input-loader {
            position: absolute;
            right: 85px;
            top: 0;
            bottom: 0;
            height: 100%;
          }
        }
      }
    }

    input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    .clear-input {
      position: relative;
      top: -8px;
      right: 15px;
      cursor: pointer;
    }
  }

  @include media-breakpoint-down(lg) {
    .x-search-input {
      .search-container {
        .search-form {
          width: 100%;
        }
      }

      .clear-input {
        position: relative;
        top: -6px;
        right: 12px;
        cursor: pointer;
      }
    }
  }
}
