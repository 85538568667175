@use '@angular/material' as mat;
@import 'components/channel-picker/channel-picker.theme';
@import 'components/channel-router/channel-router.theme';

@mixin x-channel-theme($theme-config) {
  @include x-channel-picker-theme($theme-config);
  @include x-channel-router-theme($theme-config);

  $theme: map-get($theme-config, theme);

  .x-channel-gateway {
    .x-channel-gateway-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .x-brand-emblem {
        width: 80px;
      }
    }

    .channel-selection-wrapper {
      max-width: 380px;
    }

    .channel-selection {
      display: grid;
      justify-items: center;
      gap: 15px;
    }

    p {
      text-align: center;
    }

    .channels {
      width: 100%;
      display: grid;
      gap: 10px;
    }

    .channel {
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
      grid-auto-columns: max-content;
      align-items: center;
      padding: 10px;
      cursor: pointer;

      background: mat.get-color-from-palette(map-get($theme, accent), 100);
      border: 1px solid mat.get-color-from-palette(map-get($theme, accent), 100);

      small {
        color: rgba(mat.get-color-from-palette(map-get($theme, accent), '100-contrast'), 0.5);
      }

      &.active,
      &:hover {
        background: mat.get-color-from-palette(map-get($theme, accent), 200);
        border: 1px solid mat.get-color-from-palette(map-get($theme, primary), 500);
      }
    }

    .channel-loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;

      x-brand-emblem {
        width: 80px;
      }
    }
  }
}
