@use '@angular/material' as mat;

@mixin x-order-snackbar-theme($theme-config) {
  .x-order-snackbar {
    x-media-thumbnail {
      width: 65px;
    }
    .order-item {
      display: flex;
      align-items: center;
      .info {
        padding: 5px 10px;
      }
    }
  }
}
