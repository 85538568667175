@use '@angular/material' as mat;

@mixin x-drawer-menu-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $taxon-active: mat.get-color-from-palette(map-get($theme, accent), 200);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  .x-drawer-menu {
    nav {
      a.active,
      a:hover {
        color: mat.get-color-from-palette(map-get($theme, primary), 500);
      }

      a.active {
        font-weight: 400;
      }

      .active-nodes {
        color: map-get($custom-colors, body-color-2);
        @include mat.typography-level($typography, headline-6);

        li:first-child {
          a {
            text-transform: uppercase;
          }
        }

        li:not(:first-child) {
          @include mat.typography-level($typography, body-1);
        }
      }

      .drawer-belt {
        @include mat.typography-level($typography, body-1);
      }
    }
  }
}
