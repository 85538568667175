@use '@angular/material' as mat;

@mixin x-payment-step-theme($theme-config) {
  .x-payment-step {
    .payment-accordion {
      &:empty {
        display: none;
      }

      .mat-expansion-panel-body {
        padding: 10px 20px;
        @include media-breakpoint-down(lg) {
          padding: 5px;
        }
      }
    }

    .add-card-actions {
      display: flex;
      gap: 12px;

      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
      }

      mat-checkbox {
        display: flex;
      }
    }
  }
}
