@use '@angular/material' as mat;

@mixin x-order-bag-overlay-theme($theme-config) {
  .x-order-bag-overlay {
    .mini-cart {
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 90vh;
      max-height: 90dvh;
      width: 400px;

      .body-content {
        flex: 1;
        overflow: hidden auto;
      }

      footer {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .need-help-action {
          margin-top: -10px;
          justify-content: flex-start;
        }
      }

      .no-items {
        flex: 1;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-order-bag-overlay {
      .mini-cart {
        max-height: 100%;
        height: 100%;
        width: 100%;
      }
    }
  }
}
