@use '@angular/material' as mat;

@mixin x-table-extensions($theme-config) {
  $typography: map-get($theme-config, typography);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  table.mat-mdc-table {
    width: 100%;
    th.mat-mdc-header-cell:first-of-type,
    td.mat-mdc-cell:first-of-type,
    td.mat-mdc-footer-cell:first-of-type {
      padding-left: 0;
    }

    tr.mat-mdc-row {
      height: 40px;
    }

    tr.mat-mdc-header-row {
      height: 45px;
    }

    th.mat-mdc-header-cell {
      @include mat.typography-level($typography, body-1);
      color: map-get($custom-colors, body-color-2);
    }

    th.mat-mdc-header-cell:last-of-type,
    td.mat-mdc-cell:last-of-type,
    td.mat-mdc-footer-cell:last-of-type {
      padding-right: 0;
    }

    td.mat-mdc-cell {
      border-bottom-style: none;
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 10px !important;
    }

    tbody {
      tr:last-child {
        td.mat-mdc-cell {
          border: none;
        }
      }
    }
  }
}
