@use '@angular/material' as mat;

@mixin x-product-card-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $body-content-height: 132px;
  $actions-height: 60px;
  $total-height: $body-content-height + $actions-height;

  .x-product-card {
    display: block;

    .view {
      display: flex;
      flex-direction: column;
      gap: 10px;

      a {
        display: block;
      }

      .media {
        position: relative;
        width: 100%;
        aspect-ratio: 1 / 1;
        overflow: hidden;

        video {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }

        .secondary-media {
          position: absolute;
          inset: 0;
          z-index: 0;
          opacity: 0;
          transition: opacity 300ms ease-in;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover .secondary-media {
            opacity: 1;
          }
        }

        .item-quantity {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 30px;
          height: 30px;
          background-color: mat.get-color-from-palette(map-get($theme, accent), 200);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .body-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        height: $body-content-height;
        flex-grow: 1;

        > header {
          display: -webkit-box;
          white-space: normal;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
        }

        .description {
          font-weight: 200;
        }

        .description p {
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        // Apply the same styles for all touch devices
        @media (hover: none) and (pointer: coarse) {
          height: $total-height;
        }
      }

      .actions {
        display: flex;

        .x-product-add-to-bag {
          flex: 1;
          overflow: hidden;
        }
      }

      .footer {
        display: flex;
        align-items: flex-end;

        .available-price {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 10px;

          .price {
            white-space: nowrap;
          }

          .availability {
            height: 25px;
            overflow: hidden;
          }
        }
      }

      // Mobile styles: Tablet and below
      @include media-breakpoint-down(lg) {
        .body-content {
          height: calc($total-height - 32px);
        }

        &.hide-actions .actions {
          display: none;
        }

        .footer {
          margin-top: auto;
        }

        .actions {
          margin-top: 8px;
        }
      }

      // Desktop styles with hover capability
      @include media-breakpoint-up(lg) {
        @media (hover: hover) and (pointer: fine) {
          padding-bottom: $actions-height;
          transition: padding-bottom 200ms ease-in-out;

          .actions {
            height: 0;
            opacity: 0;
            overflow: hidden;
            order: -1;
            transition:
              height 200ms ease-in-out,
              opacity 200ms ease-in-out;
          }

          .body-content {
            height: $body-content-height;
            transition: height 200ms ease-in-out;
          }

          &:hover,
          &.adding-to-cart {
            padding-bottom: 0;

            .body-content {
              height: $total-height;
            }

            .actions {
              height: $actions-height;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
