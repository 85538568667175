@use '@angular/material' as mat;

@mixin x-button-custom-types($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  .mat-mdc-button-base {
    &.type-text,
    &.type-link {
      font: inherit;
      letter-spacing: inherit;
      text-transform: unset;

      .mdc-button__label {
        font: inherit;
        letter-spacing: inherit;
      }
    }

    //mdc-button attr selector
    &[mat-button] {
      &.type-text,
      &.type-link {
        padding-left: 0;
        padding-right: 0;
        min-width: auto;
      }
    }

    &.type-link {
      display: inline-flex;
    }
    &.type-text {
      height: auto;
      color: map-get($custom-colors, text-link);
    }

    &.text-capitalize {
      text-transform: capitalize;
    }
  }
}
