@use '@angular/material' as mat;

@mixin x-delivery-address-page-theme($theme-config) {
  .x-delivery-address {
    .assigned-address {
      position: sticky;
      background: white;
      z-index: 1;
      display: block;
      padding-top: 10px;
      top: 138px;
      @include media-breakpoint-down(lg) {
        top: 126px;
      }
      margin-bottom: 40px;
    }

    .x-address-book {
      .add-address {
        background: mat.get-color-from-palette(map-get($theme, accent), 600) !important;
        color: mat.get-color-from-palette(map-get($theme, accent), '600-contrast') !important;
      }
    }

    .step-action {
      .total {
        display: none;
      }

      button {
        &.continue {
          .mdc-button__label {
            justify-content: center;
          }
        }
      }
    }
  }
}
