@use '@angular/material' as mat;

@mixin x-accordion-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $background: map-get($theme, 'background');

  .x-accordion {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 60px 0px;

    .mat-accordion {
      max-width: 640px;
      width: 100%;
      text-align: left;

      .mat-expansion-panel {
        border-top: none;
        border-left: none;
        border-right: none;

        .mat-expanded {
          background-color: transparent !important;
        }
      }
    }
  }
}
