@use '@angular/material' as mat;
@mixin x-button-icon-extensions($theme-config) {
  .mat-mdc-icon-button.mat-mdc-button-base {
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-badge-content {
      top: 0;
    }
  }
}
