@use '@angular/material' as mat;
@mixin x-main-menu-tree-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  $indent: 15px;
  .x-main-menu-tree {
    .menu-tree-node {
      display: block;
    }

    .node {
      width: 100%;
      display: flex;
      padding: 10px 16px;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      color: map-get($custom-colors, 'body-color-2');

      span {
        border-bottom: 1px solid transparent;
      }

      &:hover,
      &.active,
      &.active-ascendent {
        color: mat.get-color-from-palette(map-get($theme, 'primary'), 500);
      }

      &.depth-1 {
        text-transform: uppercase;
        @include mat.typography-level($typography, headline-6);
      }

      &.depth-2 {
        color: map-get($custom-colors, 'body-color-1');
      }

      &.depth-1 &.active:not(.depth-1) {
        span {
          border-bottom-color: mat.get-color-from-palette(map-get($theme, 'primary'), 500);
        }
      }

      &.view-all {
        color: mat.get-color-from-palette(map-get($theme, 'primary'), 500);
        font-weight: 500;
        font-size: 15px;
      }
    }

    .children {
      overflow: hidden;
      padding-left: $indent;
    }
  }
}
