@use '@angular/material' as mat;

@mixin x-order-history-detail-page-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .x-order-history-detail-page {
    .order-detail-body,
    .order-heading {
      background: mat.get-color-from-palette(map-get($theme, accent), 100);
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      padding: 10px;

      .state {
        background: mat.get-color-from-palette(map-get($theme, accent), 300);
        padding: 3px 8px;
        font-weight: 400;
        border-radius: 10px;
      }

      .order-header-actions {
        display: flex;
        gap: 5px;
        @include media-breakpoint-up(sm) {
          margin-left: auto;
        }
      }
    }

    .order-detail-wrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .order-detail-tables {
      background: mat.get-color-from-palette(map-get($theme, accent), 100);
      padding: 10px 0;

      table {
        width: 100%;
        border-collapse: collapse;
        th {
          text-align: left;
          @include mat.typography-level($typography, body-1);
        }
        td {
          vertical-align: top;
        }

        th,
        td {
          padding: 0 10px;

          &:last-child:not(:first-child) {
            text-align: right;
          }
        }

        .order-total {
          text-align: right;
        }

        tbody:not(:last-child) {
          tr:last-child {
            td,
            th {
              padding-bottom: 10px;
            }
          }
        }

        tbody.devider {
          tr:first-child {
            th,
            td {
              padding-top: 10px;
              border-top: 1px solid mat.get-color-from-palette(map-get($theme, accent), 300);
            }
          }
        }
      }
    }

    .order-items {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .order-item,
      .order-item-headings {
        display: grid;
        grid-template-columns: 4fr 1fr 1fr 1fr 150px;
        gap: 10px;
        align-items: center;
      }

      .order-item {
        .product {
          display: flex;
          align-items: center;
          gap: 20px;
          x-media-thumbnail {
            width: 80px;
          }
        }
      }
    }

    .order-items-mobile {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .order-item {
        display: grid;
        grid-template-columns: auto 3fr 130px;
        gap: 10px;
        align-items: center;

        x-media-thumbnail {
          width: 60px;
        }
      }
    }
  }
}
