@use '@angular/material' as mat;
@mixin x-shop-layout-theme($theme-config) {
  .x-shop-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    x-shop-header {
      z-index: 3;
      position: sticky;
      top: 0;
    }

    section.body {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      > x-loader {
        max-height: calc(100vh - 98px);
        z-index: 1;
      }
    }

    > footer {
      flex: 1;
    }

    > footer:empty {
      display: none;
    }

    x-loader.shop-layout-loader {
      position: fixed;
      z-index: 3;
    }
  }
}
