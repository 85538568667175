@mixin x-overlay-content-theme($theme-config) {
  .x-overlay-content {
    display: block;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-top: 5px;
    }
  }
}
