@use '@angular/material' as mat;
@mixin x-taxon-menu($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  .x-taxon-menu {
    a {
      &:hover,
      &.active {
        color: mat.get-color-from-palette(map-get($theme, primary), 500);
      }
    }

    .ascendants-menu {
      color: map-get($custom-colors, body-color-2);
      @include mat.typography-level($typography, headline-6);

      li:first-of-type,
      li:nth-of-type(1) {
        text-transform: uppercase;
      }

      li:not(:nth-of-type(1)) {
        @include mat.typography-level($typography, body-1);
      }

      fa-icon {
        padding-right: 10px;
      }
    }

    .children-menu {
      @include mat.typography-level($typography, body-1);
      .active {
        font-weight: 400;
      }
    }
  }
}
