@use '@angular/material' as mat;

@mixin x-action-list-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $background: map-get($theme, 'background');

  .x-action-list {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 0.5em !important;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      gap: 40px 10px;

      &.items-center {
        justify-content: center; // when less than 4 items, center on desktop
      }
    }

    @include media-breakpoint-down(md) {
      .list {
        &.items-center {
          justify-content: left; // always left align on mobile
        }
      }
    }

    .action-list-item {
      display: flex;
      flex-direction: column;
      flex-basis: calc(25% - 10px);
      text-align: left;

      a:not(.x-button) {
        display: block;
      }

      .x-markdown-view {
        margin-top: 20px;
        flex-wrap: wrap;
        margin-bottom: auto;
      }

      @include media-breakpoint-down(md) {
        flex-basis: calc(50% - 5px);
      }

      .x-button {
        white-space: normal;
        text-align: left;
        margin: 0;
      }
    }
  }
}
