@use '@angular/material' as mat;
@mixin x-product-listing-sort-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get($theme, custom-colors);

  .x-product-listing-sort {
    mat-form-field {
      width: 100%;
    }
  }
}
