@use '@angular/material' as mat;

@mixin x-carousel-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-carousel {
    width: 100%;
    overflow: hidden;
    .x-markdown-view {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
