@use '@angular/material' as mat;
@mixin x-typography($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  .mat-typography {
    .mat-display-1 {
      @include mat.typography-level($typography, display-1);
    }

    .mat-display-2 {
      @include mat.typography-level($typography, display-2);
    }

    .mat-display-3 {
      @include mat.typography-level($typography, display-3);
    }

    .mat-display-4 {
      @include mat.typography-level($typography, display-4);
    }

    .mat-display-5 {
      @include mat.typography-level($typography, display-5);
    }

    .mat-subtotal {
      @include mat.typography-level($typography, subtotal);
    }

    .mat-total {
      @include mat.typography-level($typography, total);
    }

    h1 {
      @include mat.typography-level($typography, headline-1);
    }
    h2 {
      @include mat.typography-level($typography, headline-2);
    }
    h3 {
      @include mat.typography-level($typography, headline-3);
    }
    h4 {
      @include mat.typography-level($typography, headline-4);
    }
    h5 {
      @include mat.typography-level($typography, headline-5);
    }
    h6 {
      @include mat.typography-level($typography, headline-6);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .mat-headline-1,
    .mat-headline-2,
    .mat-headline-3,
    .mat-headline-4,
    .mat-headline-5,
    .mat-headline-6 {
      &:not(.white-text) {
        color: map-get($custom-colors, heading-color);
      }
      -webkit-font-smoothing: antialiased;
      margin: 0;
    }

    .mat-subtitle-2 {
      text-transform: uppercase;
    }

    h5,
    h6,
    .mat-headline-5,
    .mat-headline-6 {
      -webkit-font-smoothing: subpixel-antialiased;
    }

    .mat-body-1:not(p) {
      margin: 0;
    }
  }
}
