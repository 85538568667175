@use '@angular/material' as mat;
@mixin x-wallet-transactions-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-wallet-transactions {
    table.wallet-transactions {
      thead {
        font-weight: 400;
        color: map-get($custom-colors, body-color-1);
        td {
          border-bottom: 1px solid map-get($custom-colors, borders);
        }
      }

      tbody {
        tr {
          transition: background ease-in-out 300ms;
          background: white;

          &:hover {
            background: mat.get-color-from-palette(map-get($theme, accent), 100);
          }
        }
      }
    }
  }
}
