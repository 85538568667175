@use '@angular/material' as mat;

@mixin x-order-history-page-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-order-history-page {
    .year-filter {
      display: flex;
      gap: 20px;
      align-items: center;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .order-history {
      > header {
        align-items: flex-end;
      }
    }

    .no-orders {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .order-cards {
      @include shop-grid-layout(3, 20px);

      @include media-breakpoint-down(lg) {
        @include shop-grid-layout(3, 10px);
      }

      @include media-breakpoint-down(md) {
        @include shop-grid-layout(2, 10px);
      }

      @include media-breakpoint-down(sm) {
        @include shop-grid-layout(1, 10px);
      }
    }
  }
}
