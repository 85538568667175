@mixin print-view($theme, $typography) {
  @media print {
    .mat-drawer {
      display: none !important;
    }

    .x-shop-header {
      display: none !important;
    }

    footer {
      display: none !important;
    }

    .x-shop-layout {
      padding: 0 !important;
    }
  }
}
