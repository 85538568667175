@use '@angular/material' as mat;

@mixin x-subscriber-checkout-summary-theme($theme-config) {
  $typography: map-get($theme-config, typography);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-subscriber-checkout-summary {
    .sections {
      display: flex;
      gap: 10px;
      flex-direction: column;

      section {
        @include mat.typography-level($typography, display-2);
        > span {
          font-weight: 400;
        }
      }
    }
    .footer {
      &:empty {
        display: none;
      }

      margin-top: 10px;
      padding-top: 15px;

      @include media-breakpoint-down(lg) {
        margin-top: 5px;
        padding-top: 15px;
      }

      border-top: 1px solid map-get($custom-colors, borders);
    }

    @include media-breakpoint-down(lg) {
      x-subscriber-checkout-header {
        .container {
          padding: 0;
        }
      }
    }
  }
}
