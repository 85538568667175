@mixin x-infinite-scroll-collection-theme($theme-config) {
  .x-infinite-scroll-collection {
    display: block;
    overflow: hidden;
    position: relative;

    .product-list-viewport {
      width: 100%;
    }

    .cdk-virtual-scroll-content-wrapper {
      width: 100%;
      max-width: 100%;
    }

    .product-list-row {
      padding-bottom: 5px;
    }

    @include media-breakpoint-down(lg) {
      .product-list-column {
        overflow: unset;
      }
    }
  }
}
