@use '@angular/material' as mat;
@mixin x-wallet-transaction-status-dialog-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-wallet-transaction-status-dialog {
    .icon-status {
      &.success {
        border: 1px solid mat.get-color-from-palette(map-get($theme, primary), 500);
        color: mat.get-color-from-palette(map-get($theme, primary), 500);
      }

      &.fail {
        border: 1px solid mat.get-color-from-palette(map-get($theme, warn), 500);
        color: mat.get-color-from-palette(map-get($theme, warn), 500);
      }
    }

    .info {
      table {
        tr {
          td:first-child {
            font-weight: 400;
          }
        }
      }
    }
  }
}
