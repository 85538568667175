@mixin x-content-component-display($theme-config) {
  .component-hide {
    display: none;
  }

  .component-show {
    display: block;
  }

  .component-small {
    @include media-breakpoint-between(xs, md) {
      display: block !important;
    }
  }

  .component-medium {
    @include media-breakpoint-only(md) {
      display: block !important;
    }
  }

  .component-large {
    @include media-breakpoint-up(lg) {
      display: block !important;
    }
  }
}
