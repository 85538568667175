@use '@angular/material' as mat;

@mixin x-checkout-delivery-slot-rows-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-checkout-delivery-slot-rows {
    color: mat.get-color-from-palette(map-get($theme, primary), 500);

    .day {
      @include get-font($theme-config, body-1);
      font-weight: 400;
    }

    .time {
      @include get-font($theme-config, display-4);
    }
  }
}
