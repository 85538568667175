@use '@angular/material' as mat;
@mixin x-brand-emblem-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-brand-emblem {
    svg {
      fill: mat.get-color-from-palette(map-get($theme, primary), 500);
    }
  }
}
