@use '@angular/material' as mat;

@mixin x-product-swiper-slider-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .x-product-swiper-slider {
    .swiper-slide {
      height: unset;
    }

    .swiper-button-prev,
    .swiper-button-next {
      margin-top: -100px;
    }

    .view-all {
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1/1;
      @include mat.typography-level($typography, button);
      text-transform: uppercase;

      &.detailed {
        background-color: mat.get-color-from-palette(map-get($theme, accent), 100);
        button {
          display: flex;
          align-items: center;
          background-color: white;
          max-width: 300px;
          cursor: pointer;

          span {
            display: block;
            height: fit-content;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            direction: ltr;
          }
        }
      }

      &.simple {
        cursor: pointer;
        background-color: mat.get-color-from-palette(map-get($theme, accent), 200);
      }
    }
  }
}
