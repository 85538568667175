@use '@angular/material' as mat;
@import 'components/product-listing-sort/product-listing-sort.theme';

@mixin x-product-listing-theme($theme-config) {
  @include x-product-listing-sort-theme($theme-config);

  $theme: map-get($theme-config, theme);

  .x-product-listing {
    .product-list-column {
      overflow: unset;
    }

    @include media-breakpoint-down(lg) {
      .x-product-listing-sort {
        position: sticky;
        top: 49px;
        z-index: 2;
        padding: 0 14px;
        background: mat.get-color-from-palette(map-get($theme, 'accent'), 200);
        color: mat.get-color-from-palette(map-get($theme, 'accent'), 200-contrast);
        border-bottom: none;
      }
    }
  }
}
