@use '@angular/material' as mat;

@mixin x-page-panel-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);
  .x-page-panel {
    position: relative;

    .actions-top {
      display: flex;
      gap: 10px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid map-get($custom-colors, borders);

      &:empty {
        display: none;
      }
    }

    > header {
      display: flex;
      gap: 10px;

      h1 {
        @include mat.typography-level($typography, headline-2);
      }

      .action-left:empty,
      .action-right:empty {
        display: none;
      }

      &.header-align-left {
        .action-right {
          margin-left: auto;
        }
      }

      &.header-align-right {
        .action-left {
          margin-right: auto;
        }
      }

      &.header-align-center {
        justify-content: center;
        .action-left,
        .action-right {
          flex: 1 0;
          &:empty {
            display: block;
          }
        }
        .heading-text {
          flex-grow: 1;
          align-items: center;
          justify-content: center;

          .heading {
            text-align: center;
          }
        }
      }

      .heading-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;

        > .heading {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }
      }

      .action-left {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .action-right {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        align-items: flex-start;
      }
    }

    > .footer-actions {
      &:empty {
        display: none;
      }

      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}
