@use '@angular/material' as mat;

@import 'mat-field-extensions';
@import 'select-extensions';
@import 'textarea-extensions';
@import 'optgroup-extensions';

@mixin x-form-extensions($theme-config) {
  form {
    mat-form-field:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @include x-mat-field-extensions($theme-config);
  @include x-select-extensions($theme-config);
  @include x-textarea-extensions($theme-config);
  @include x-optgroup-extensions($theme-config);
}
