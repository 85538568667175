@use '@angular/material' as mat;
@mixin x-checkout-promo-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $theme: map-get($theme-config, theme);

  .x-checkout-promo {
    display: block;

    .promo-code-control.promo-code-applied {
      .mat-mdc-text-field-wrapper {
        border-color: map-get($custom-colors, heavy-success);
      }
    }

    .promo-price-free {
      color: #fff;
      @include mat.typography-level(map-get($theme-config, typography), caption);
      background-color: map-get($custom-colors, badge-bg);
      letter-spacing: 0.15em;
    }

    .promo-applied {
      margin-right: 10px;

      fa-icon {
        color: map-get($custom-colors, heavy-success);
      }

      + input {
        display: inline-flex;
        width: auto;
        max-width: 100%;
      }
    }

    .promo-code {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .mat-mdc-form-field {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .mat-mdc-text-field-wrapper {
        position: relative;
        overflow: hidden;
        align-items: center;

        .mat-mdc-form-field-infix {
          display: flex;
        }

        .mat-mdc-form-field-icon-suffix {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          padding: 0;
        }
      }

      .promo-code-button-section {
        display: flex;
        justify-content: center;

        button {
          height: 59px;
          &.promo-code-apply {
            background: mat.get-color-from-palette(map-get($theme, accent), 600);
            color: mat.get-contrast-color-from-palette(map-get($theme, accent), 600);
          }
        }
      }
    }

    .coupon-code {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
