@use '@angular/material' as mat;

@mixin x-header-layout-theme($theme-config) {
  .x-header-layout {
    .header-layout {
      .actions-left,
      .actions-right {
        gap: 10px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-header-layout {
      .header-layout {
        gap: 5px;

        .actions-left {
          gap: 0;
        }
        .actions-right {
          gap: 0;
        }
      }
    }
  }
}
