@use '@angular/material' as mat;

@mixin x-section-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');

  .x-section {
    .section {
      display: flex;
      flex-wrap: wrap;

      justify-content: center;
      &.text-pos {
        &-start {
          justify-content: flex-start;
        }
        &-end {
          justify-content: end;
        }
      }
    }

    *.mid-column {
      border-collapse: collapse;
    }

    @include media-breakpoint-down(md) {
      *.mid-column {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 2.5em;
      }
      .three {
        .column-one,
        .column-two,
        .column-three {
          padding-top: 40px;
          padding-bottom: 50px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .column-dividers {
        .column-two,
        .column-three {
          border-left: 1px solid currentColor;
        }
      }

      .column-one,
      .column-two,
      .column-three {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      *.mid-column {
        border-collapse: collapse;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        border-right: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    h2 + .x-button {
      margin-top: 0;
      margin-bottom: 0;
    }

    .x-markdown-view {
      table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        tr {
          td,
          th {
            width: 100%;
            border-collapse: collapse;
            padding: 24px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            vertical-align: top;
            text-align: center;
            box-sizing: border-box;

            @include media-breakpoint-up(md) {
              text-align: left;
            }

            @include media-breakpoint-down(sm) {
              display: block;
            }

            &:first-child {
              padding: 24px 32px;
              @include media-breakpoint-up(md) {
                padding: 24px 96px;
              }
            }
            h1,
            h2,
            h3,
            h4 {
              font-weight: normal;
              &:last-child {
                // margin-bottom: 1em;
              }
            }
          }
          th:empty {
            display: none;
          }
        }
      }

      a.x-button {
        margin: 0;
        margin-top: 12px;
      }

      ol,
      ul {
        padding-left: 20px;

        @include media-breakpoint-down(xs) {
          display: inline-block;
          text-align: left;
          margin: auto;
        }
      }

      img,
      video {
        height: auto;
        width: auto;
        max-width: 100%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 20px;
      }

      a {
        color: #4d7eb8;
      }
    }

    .text-view {
      max-width: 720px;
      padding: 0 18px;
      text-align: center;

      &.align-left {
        text-align: start;
      }
      &.align-right {
        text-align: right;
      }
    }

    .button-section {
      padding-top: 20px;
    }
  }
}
