@use '@angular/material' as mat;

@mixin x-subscription-detail-theme($theme-config) {
  .x-subscription-detail {
    .heading-text {
      max-width: 720px;

      .sub-heading {
        margin-top: 15px;
      }
    }

    .subscription {
      text-align: center;
    }

    .variant {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      padding: 0 12px;

      tr {
        td:first-child {
          font-weight: 400;
        }
      }
    }

    .terms {
      display: flex;
      align-items: center;
      gap: 8px;
      mat-checkbox {
        display: flex;
      }
    }

    .subscribe {
      display: flex;
      align-items: center;
      gap: 5px;

      .mat-mdc-form-field {
        height: 53px;
        overflow: hidden;
        min-width: 250px;
      }

      button {
        height: 53px;
      }
    }

    @include media-breakpoint-down(lg) {
      .subscribe {
        gap: 5px;
        flex-direction: column;

        button {
          height: 53px;
          width: 100%;
        }
      }
    }
  }
}
