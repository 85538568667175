@use '@angular/material' as mat;

@mixin x-delivery-step-theme($theme-config) {
  .x-delivery-step {
    x-banner-button {
      display: flex;
      box-sizing: border-box;
      height: 70px;
      align-items: center;
    }

    @include media-breakpoint-down(lg) {
      x-banner-button {
        height: 70px;

        .banner-button {
          .actions {
            flex-grow: 1;
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
