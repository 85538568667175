@use '@angular/material' as mat;
@mixin x-button-stroked-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  .mat-mdc-outlined-button {
    &.mat-primary {
      border: 1px solid mat.get-color-from-palette(map-get($theme, primary));
    }

    &.mat-warn {
      border: 1px solid mat.get-color-from-palette(map-get($theme, warn));
    }

    &[disabled] {
      border: 1px solid #ddd;
    }
  }
}
