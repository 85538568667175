@use '@angular/material' as mat;

@mixin x-banner-theme($theme-config) {
  .banner-wrapper {
    position: relative;
    .card-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      pointer-events: none;

      .card-boundary {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 42px;
        .content {
          max-width: 600px;
          text-align: left;

          &.white-text {
            color: white;
          }

          *.x-button {
            margin: 0;
            pointer-events: all;
          }
        }
      }
    }
  }
}
