@use '@angular/material' as mat;
@mixin x-button-custom-colors($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .mat-mdc-button-base {
    &.mat-accent:not([disabled]) {
      background: map-get($custom-colors, accent-fill-buttons-bg);
      color: map-get($custom-colors, accent-fill-buttons-color);
    }
  }
}
