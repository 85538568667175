@use '@angular/material' as mat;
@mixin x-banner-button-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $active-banner-color: mat.get-color-from-palette(map-get($theme, accent), 100);
  $banner-highlights: mat.get-color-from-palette(map-get($theme, accent), 200);

  .x-banner-button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .x-banner-button {
    display: block;
    border: 1px solid mat.get-color-from-palette(map-get($theme, accent), 600);
    background: white;
    transition:
      border ease-in-out 300ms,
      background ease-in-out 300ms;

    .radio {
      display: flex;
      align-items: center;
      justify-content: center;

      width: fit-content;
      padding-right: 5px;

      .radio-icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid mat.get-color-from-palette(map-get($theme, primary), 500);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;

        .dot {
          background: mat.get-color-from-palette(map-get($theme, primary), 500);
          border-radius: 50%;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: opacity ease-in-out 300ms;
        }
      }
    }

    &.active {
      @include active-state($theme);
    }

    &:hover {
      @include media-breakpoint-up(lg) {
        // @include active-state($theme);
        @include hover-state($theme);
      }
    }

    .banner-button {
      cursor: pointer;
      position: relative;
      display: flex;
      flex: 1;
      padding: 15px;
      gap: 10px;

      .icon-prefix,
      .icon-suffix {
        display: flex;
        align-items: center;
        &:empty {
          display: none;
        }

        &.top {
          align-items: flex-start;
        }
        &.bottom {
          align-items: flex-end;
        }
      }

      .content-wrap {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 5px;
        overflow: hidden;
      }

      .content {
        flex-grow: 1;
        width: 100%;

        > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .instructions {
          background: $banner-highlights;
          display: inline-block;

          div {
            display: -webkit-box;
            text-wrap: wrap;
            box-sizing: border-box;

            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;

            width: fit-content;
          }
          &:empty {
            display: none;
          }
        }
      }

      .actions {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-grow: 0;
        height: 100%;
        justify-content: center;
      }

      .icon {
        flex-grow: 0;
      }

      .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px 5px;
        text-transform: uppercase;
        transition:
          background ease-in-out 300ms,
          color ease-in-out 300ms;
        background: $banner-highlights;
        color: mat.get-color-from-palette(map-get($theme, accent), '300-contrast');
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-banner-button {
      .banner-button {
        padding: 15px;
        .content-wrap {
          gap: 10px;
          .actions {
            gap: 0px;
            flex-direction: row;

            .mat-mdc-button-base {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@mixin active-state($theme) {
  $active-banner-color: mat.get-color-from-palette(map-get($theme, accent), 100);

  cursor: pointer;
  border: 1px solid $active-banner-color;
  background-color: $active-banner-color;

  .radio-icon {
    .dot {
      opacity: 1;
    }
  }

  .banner-button {
    .tag {
      background: mat.get-color-from-palette(map-get($theme, primary), 500);
      color: mat.get-color-from-palette(map-get($theme, primary), '500-contrast');
    }
  }
}

@mixin hover-state($theme) {
  $active-banner-color: mat.get-color-from-palette(map-get($theme, accent), 100);

  cursor: pointer;
  border: 1px solid $active-banner-color;
  background-color: $active-banner-color;

  .banner-button {
    .tag {
      background: mat.get-color-from-palette(map-get($theme, primary), 500);
      color: mat.get-color-from-palette(map-get($theme, primary), '500-contrast');
    }
  }
}
