@use '../../../../../../../../node_modules/@angular/material/index' as mat;

@mixin x-region-button-icon-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  x-region-button-icon {
    button {
      display: block;
      position: relative;

      .mat-badge-content {
        margin: unset !important;
        right: auto;
        left: 8px;
      }

      .region-not-set {
        .mat-badge-content {
          background: map-get($custom-colors, region-button-not-selected-bg);
          color: map-get($custom-colors, region-button-not-selected-color);
        }
      }

      .region-set {
        .mat-badge-content {
          background: map-get($custom-colors, region-button-selected-bg);
          color: map-get($custom-colors, region-button-selected-color);
        }
      }
    }
  }
}
