@use '@angular/material' as mat;

@mixin x-card-grid-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $background: map-get($theme, 'background');
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');

  .x-card-grid {
    .x-markdown-view {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 12px;
      }
    }

    .card-grid-item {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 48px 24px;

      img {
        width: 48px;
        height: 48px;
        margin-bottom: 32px;
      }
    }

    .default {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .card-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        .card-grid-item {
          width: 26vw;
          box-sizing: border-box;
        }

        @include media-breakpoint-down(sm) {
          grid-template-columns: repeat(1, 1fr);

          .card-grid-item {
            width: 100%;
          }
        }
      }
    }

    .boxed {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 640px;

      flex-wrap: wrap;

      .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .card-grid-item {
          margin: 5px;
          padding: 20px 5px 5px 5px;
          flex-basis: calc(33.33% - 10px); /* Adjust basis and subtract total horizontal margins */
          box-sizing: border-box;
          border-radius: 0.2em;
          background-color: mat.get-color-from-palette($accent, 100);
          flex-grow: 1;
          flex-shrink: 1;

          img {
            width: 24px;
            height: 24px;
            margin-bottom: 8px;
          }
        }

        a {
          margin: 5px;
          display: flex;
          justify-content: center;

          flex-grow: 1;
          flex-shrink: 1;

          flex-basis: calc(33.33% - 10px);
          box-sizing: border-box;

          .card-grid-item {
            margin: 0px;
          }
        }
      }
    }
  }
}
