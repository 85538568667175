@use '@angular/material' as mat;
@mixin x-region-overlay-theme($theme-config) {
  $gap: 20px;
  $inputHeight: 58px;

  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-region-overlay {
    display: block;
    width: 100%;
    position: relative;
    height: 100%;

    .map-column {
      min-height: 400px;

      x-region-overlay-footer {
        display: none;
      }
    }

    .region-details {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 32px;
    }

    x-region-normal-flow,
    x-region-add-to-cart-flow {
      display: flex;
      flex-direction: column;

      header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding-bottom: 20px;

        x-close-button {
          margin-left: auto;

          @include media-breakpoint-down(lg) {
            position: relative;
            top: -10px;
            right: -20px;
          }
        }

        .heading {
          h2 {
            padding-bottom: 10px;
          }
        }
      }
      .body-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
      }
    }

    x-region-search {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .region-search {
        display: flex;
        align-items: center;
        gap: 10px;
        .address-search {
          flex: 1;
          height: $inputHeight;
        }
        .mat-mdc-button-base {
          height: $inputHeight;
        }
      }
      .login {
        display: flex;
      }
    }

    x-region-delivery-details {
      color: mat.get-color-from-palette(map-get($theme, primary), 500);

      .mat-caption {
        color: map-get($custom-colors, heavy-success);
      }
    }

    x-region-goto-channel {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      align-items: center;
      button {
        flex-shrink: 0;
      }
    }

    x-region-add-to-cart-detail {
      display: flex;
      flex-direction: column;
      gap: $gap;
      .note {
        display: flex;
        gap: $gap;
        color: mat.get-color-from-palette(map-get($theme, primary), 500);

        &.not-available {
          color: mat.get-color-from-palette(map-get($theme, warn), 500);
          fa-icon {
            margin-top: 5px;
          }
        }

        fa-icon {
          margin-top: 3px;
        }
      }
      .actions {
        display: flex;
        align-items: center;
        .region {
          flex: 1 1 auto;
        }
        button {
          flex-shrink: 0;
        }
      }
    }

    x-region-collect {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .collect {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      @include media-breakpoint-down(lg) {
        margin: auto 0;
      }
    }

    x-region-overlay-footer {
      flex-grow: 0;
      margin-top: auto;
      padding-top: $gap;
      border-top: 1px solid map-get($custom-colors, borders);
      display: flex;
      flex-direction: column;
      gap: $gap;
      .footnote {
        display: flex;
        gap: $gap;
        align-items: center;
        padding: 6px 0;
      }

      .actions {
        display: none;
        gap: 10px;
        button {
          flex: 1;
        }
      }

      .mobile-bottom-buttons {
        display: none;
      }
    }

    /*hide all empty comp*/
    x-region-delivery-details,
    x-region-collect,
    x-region-goto-channel,
    x-region-add-to-cart-detail {
      &:empty {
        display: none;
      }
    }

    .all-caps {
      text-transform: uppercase;
    }
  }

  // mobile
  @include media-breakpoint-down(lg) {
    .x-region-overlay {
      > .container-fluid {
        &,
        & > .row {
          height: 100%;
        }
      }

      .map-column {
        padding: 0;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        flex-direction: column;
        transform: translateY(100%);
        display: flex;
        transition: transform ease-in-out 200ms;
        background: white;

        &.mobile-active {
          transform: translateY(0%);
        }

        x-region-overlay-footer {
          display: flex;
        }
      }

      .region-details {
        height: 100%;
        padding: 0;
      }

      x-region-normal-flow,
      x-region-add-to-cart-flow {
        padding: 10px 20px 10px 20px;
        height: 100%;
      }

      x-region-search {
        .region-search {
          .confirm-button {
            display: none;
          }
        }
      }

      x-region-overlay-footer {
        padding: 20px;
        .actions {
          display: flex;
        }
      }
    }
  }
}
