@use '@angular/material' as mat;
@mixin x-checkout-delivery-slots-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  $timeslotsWidth: 130px;
  $firstRowHeight: 70px;
  $rowHeight: 50px;

  .x-checkout-delivery-slots {
    .slot-picker {
      border: solid mat.get-color-from-palette(map-get($theme, accent), 600);
      border-width: 1px 0 0 1px;

      > .slot-cols {
        display: flex;
        overflow: hidden;
        > .slot-col {
          width: calc(100% - $timeslotsWidth);

          @include media-breakpoint-down(lg) {
            width: calc(100% - 100px);
          }

          &:first-child {
            width: $timeslotsWidth;
            @include media-breakpoint-down(lg) {
              width: 100px;
            }
          }

          &.time {
            @include mat.typography-level($typography, display-3);
            @include media-breakpoint-down(lg) {
              @include mat.typography-level($typography, caption);
            }
          }
        }
      }

      .slot-row {
        height: $rowHeight;

        &.heading {
          height: $firstRowHeight;
          gap: 5px;
        }
      }

      .cell {
        border: solid mat.get-color-from-palette(map-get($theme, accent), 600);
        border-width: 0 1px 1px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .slot {
        background: white;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background ease 300ms;
        &.not-avail {
          background: #f4f4f4;
          cursor: not-allowed;
        }

        &.can-select {
          cursor: pointer;
        }

        &.selected,
        &:hover:not(.not-avail) {
          background: mat.get-color-from-palette(map-get($theme, accent), 100);

          color: mat.get-color-from-palette(map-get($theme, accent), '500-contrast');
        }
      }

      .earliest-delivery {
        border: solid mat.get-color-from-palette(map-get($theme, accent), 600);
        border-width: 0 1px 1px 0;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: mat.get-color-from-palette(map-get($theme, accent), 100);
      }
    }

    .navigation {
      display: flex;
      justify-content: space-between;

      .mdc-button {
        @include mat.typography-level($typography, title-case-link);
      }

      div {
        display: flex;
        gap: 10px;

        .mdc-button__label {
          gap: 8px;
        }
      }
    }

    .swiper-button-disabled {
      cursor: not-allowed;
    }

    .navigation-months {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'prev next';

      .nav-prev-month {
        grid-area: prev;
        justify-self: flex-start;
      }
      .nav-next-month {
        grid-area: next;
        justify-self: end;
      }
    }
  }
}
