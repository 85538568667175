@use '@angular/material' as mat;
@mixin x-checkout-summary-order-accordian-theme($theme-config) {
  .x-checkout-summary-order-accordian {
    .mat-expansion-panel-body {
      padding-bottom: 0;
    }

    .order-items {
      max-height: 200px;
      overflow: hidden auto;
    }
  }
}
