@mixin x-media-thumbnail-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $background: map-get($custom-colors, bg-light);

  .x-media-thumbnail {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      display: block;
    }

    x-loader {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($background, 0.8);
    }
  }
}
