@use '@angular/material' as mat;

@mixin x-general-snackbar-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .general-snackbar {
    padding: 5px;
    background: mat.get-color-from-palette(map-get($theme, accent), 500);
    color: mat.get-color-from-palette(map-get($theme, primary), '500-contrast');
  }
}
