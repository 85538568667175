@mixin x-order-recreate-dialog-theme($theme-config) {
  .x-recreate-cart-dialog {
    display: block;
    max-height: 90vh;
    height: 100%;
    @include media-breakpoint-down(lg) {
      max-height: 100vh;
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .item {
      display: grid;
      grid-template-columns: 36px auto;
      gap: 10px;
      align-items: center;
    }
  }
}
