@use '@angular/material' as mat;

@mixin x-footer-content-theme($theme-config) {
  .x-footer-content {
    @include media-breakpoint-down(lg) {
      .footer-content {
        align-items: center;

        > p {
          text-align: center;
        }

        form {
          flex-direction: column;

          mat-form-field {
            width: 100%;
          }
        }
      }
    }
  }
}
