@use '@angular/material' as mat;
@mixin x-checkout-stepper-breadcrumb-theme($theme-config) {
  .crumb {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    .name {
      text-transform: capitalize;
      text-align: center;
    }
  }

  @include media-breakpoint-down(lg) {
    .crumb {
      justify-content: space-between;
      display: flex;

      .name.no-back-nav {
        text-align: left;
      }
    }
  }
}
