@use '@angular/material' as mat;

@mixin x-ad($theme-config) {
  .x-ad {
    position: relative;
    display: block;

    &:empty {
      display: none;
    }

    .shopId {
      position: absolute;
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.v_banner {
      margin-right: 15px;
    }

    &.card {
      height: 100%;
      img,
      video {
        @include media-breakpoint-up(lg) {
          height: calc(100% - 55px);
        }
      }
    }
  }
}
