@use '@angular/material' as mat;

@mixin x-region-user-addresses-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .x-region-user-addresses {
    .saved-addresses {
      background: mat.get-color-from-palette(map-get($theme, accent), 100);
      padding-top: 10px;

      .saved-addresses-heading {
        color: mat.get-color-from-palette(map-get($theme, primary));
        padding: 5px 10px;
      }

      .saved-address {
        @include mat.typography-level($typography, display-3);
        display: flex;
        align-items: center;
        padding: 8px 10px;
        gap: 10px;

        .address {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover {
          cursor: pointer;
          background: mat.get-color-from-palette(map-get($theme, accent), 200);
        }
      }
    }
  }
}
