@use '@angular/material' as mat;

@mixin x-expansion-panel-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  $border-color: map-get($custom-colors, borders);
  $panel-height: 45px;

  $header-color: mat.get-color-from-palette(map-get($theme, accent), 100) !important;

  mat-accordion {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  mat-expansion-panel {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid mat.get-color-from-palette(map-get($theme, accent), 400);

    &.mat-expansion-panel-spacing {
      margin: 0;
    }
  }

  mat-expansion-panel-header {
    height: $panel-height !important;
    padding: 0 15px;
    @include mat.typography-level($typography, body-2);
    background: white !important;
    font-weight: 300;
    border-radius: 0 !important;
    transition: background ease-in-out 300ms;

    .mat-expansion-indicator {
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M24,38c-0.8,0-1.6-0.3-2.1-0.9L0.4,15.7c-0.6-0.6-0.6-1.5,0-2.1C1,13,2,13,2.6,13.6L24,35l21.4-21.4  c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1L26.1,37.1C25.6,37.7,24.8,38,24,38C24,38,24,38,24,38z"/></svg>')
        no-repeat center;

      &:after {
        padding: 7px;
        opacity: 0;
      }
    }

    mat-panel-title {
      fa-icon {
        margin-right: 10px;
      }
    }

    &.mat-expanded,
    &:hover {
      background: $header-color;
    }
  }

  .mat-expansion-panel-body {
    background: white;
    padding: 20px;
    position: relative;
  }

  /*clean*/

  mat-accordion.clean {
    mat-expansion-panel {
      border: none;
      border-bottom: 1px solid map-get($custom-colors, borders);

      &:last-child {
        border: none;
        padding-bottom: 0;
      }
    }

    mat-expansion-panel-header {
      background: none !important;
      height: 60px !important;
      padding: 0;
    }

    .mat-expansion-panel-body {
      background: white;
      padding: 10px 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .mat-expansion-panel-body {
      padding: 10px;
    }
  }
}
