$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1360px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px,
);

$enable-negative-margins: true;

@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/bootstrap-utilities';

@mixin bootstrap-config() {
  .container,
  .container-fluid {
    box-sizing: border-box;
  }
}

@mixin generate-up-down-visibility-classes {
  @each $breakpoint in map-keys($grid-breakpoints) {
    .visible-#{$breakpoint}-up {
      display: none !important;
      @include media-breakpoint-up($breakpoint) {
        display: block !important;
      }
    }

    .visible-#{$breakpoint}-down {
      display: none !important;
      @include media-breakpoint-down($breakpoint) {
        display: block !important;
      }
    }
  }
}

@include generate-up-down-visibility-classes;
