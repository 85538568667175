@use '@angular/material' as mat;
@mixin x-address-book-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-address-book {
    .address-button {
      .banner-button {
        min-height: 100px;
      }
    }

    .instructions {
      background: mat.get-color-from-palette(map-get($theme, accent), 300);
    }

    .alias {
      background: mat.get-color-from-palette(map-get($theme, accent), 300);
    }

    .x-banner-button {
      &.active {
        .instructions {
          background: mat.get-color-from-palette(map-get($theme, accent), 300);
        }
      }

      button {
        @include mat.typography-level($typography, title-case-link);
      }
    }

    .add-address {
      background: mat.get-color-from-palette(map-get($theme, primary), 500) !important;
      color: mat.get-color-from-palette(map-get($theme, primary), '500-contrast') !important;
    }

    .actions {
      gap: 0px;
      button {
        padding: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      gap: 5px;
    }

    .address-info {
      text-wrap: wrap;
    }
  }
}
