@use '@angular/material' as mat;

@mixin x-membership-detail-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .x-membership-detail {
    .membership-signup {
      text-align: left;
      max-width: 720px;
      margin: 0 auto;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .membership-name {
      text-align: center;
      margin-bottom: 0.5em;
    }

    .terms {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      mat-checkbox {
        display: flex;
      }
    }

    .membership-actions {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      gap: 12px;
      row-gap: 12px;
      .mat-mdc-button-base {
        width: 100%;
      }
    }

    .membership-status {
      text-align: center;
    }

    .member-active {
      @include mat.typography-level($typography, body-2);
      color: mat.get-color-from-palette(map-get($theme, primary), 500);
    }

    .member-inactive {
      @include mat.typography-level($typography, body-2);
      color: mat.get-color-from-palette(map-get($theme, warn), 500);
    }

    @include media-breakpoint-up(sm) {
      .membership-actions {
        .mat-mdc-button-base {
          width: unset;
        }
      }
    }
  }
}
