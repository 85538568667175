@use '@angular/material' as mat;
@mixin x-checkout-summary-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-checkout-summary {
    color: map-get($custom-colors, body-color-1);

    .sections {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;

      .need-help-action {
        margin-top: -10px;
        justify-content: flex-start;
      }
    }

    .step-action {
      .need-help-action {
        display: none;
      }
    }

    @include media-breakpoint-down(lg) {
      .need-help-action {
        display: none;
      }
    }
  }
}
