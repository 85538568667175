@use '@angular/material' as mat;
@mixin x-checkout-stepper-nav-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  @include media-breakpoint-up(lg) {
    .x-checkout-stepper-nav {
      .stepper-nav {
        display: flex;
        justify-content: space-between;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          top: 50%;
          background-color: map-get($custom-colors, borders);
        }
      }
      .step-nav {
        display: flex;
        position: relative;
        z-index: 1;
        padding: 0 10px;
        cursor: pointer;
        background: white;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }

        .step-status {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          color: white;
          background-color: mat.get-color-from-palette(map-get($theme, accent), 500);
          transition: background-color ease-in-out 300ms;
          border-radius: 50%;

          fa-icon {
            display: none;
            color: white;
          }
        }
        .mdc-button__label {
          display: flex;
          align-items: center;
        }

        &.checked {
          .step-status {
            span {
              display: none;
            }
            fa-icon {
              display: inline-flex;
            }
          }
        }

        // statuses color
        &.active,
        &.checked,
        &:hover {
          color: mat.get-color-from-palette(map-get($theme, primary), 500);

          .step-status {
            background-color: mat.get-color-from-palette(map-get($theme, primary), 500);
            color: mat.get-color-from-palette(map-get($theme, primary), '500-contrast');
          }
        }
        &[disabled] {
          color: rgba(0, 0, 0, 0.5);
          cursor: default;
          pointer-events: none;

          .step-status {
            color: rgba(0, 0, 0, 0.2);
            background-color: rgba(0, 0, 0, 0.12);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-checkout-stepper-nav {
      .stepper-nav {
        display: flex;
        gap: 5px;
      }

      .step-nav {
        flex: 1;
        background: mat.get-color-from-palette(map-get($theme, accent), 100);

        &.active {
          background: mat.get-color-from-palette(map-get($theme, accent), 300);
        }
      }

      .step-status {
        display: none;
      }
    }
  }
}
