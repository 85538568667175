@use '@angular/material' as mat;

@mixin x-order-item-theme($theme-config) {
  .x-order-item {
    position: relative;

    .item.compact {
      display: flex;
      gap: 10px;
      .media {
        flex-shrink: 0;
        width: 100px;
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        header {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .item-price {
          display: flex;
          align-items: center;
          gap: 5px;

          .subtotal {
            white-space: nowrap;
          }

          .x-order-item-availability {
            overflow: hidden;
          }
        }

        .content-footer {
          display: flex;
          align-items: center;
          gap: 10px;

          .quantity-only {
            height: 36px;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .action {
        flex-shrink: 0;
        display: flex;
        align-items: center;
      }
    }

    .item.expand {
      @include order-item-expand-grid;

      .product {
        display: flex;
        gap: 10px;
        .title {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .media {
          flex-shrink: 0;
        }
      }
    }

    .promo-price-free {
      color: #fff;
      @include mat.typography-level(map-get($theme-config, typography), caption);
      background-color: get-custom-color($theme-config, badge-bg);
      letter-spacing: 0.15em;
    }
  }
}
