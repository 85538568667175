@use '@angular/material' as mat;

@mixin x-shop-header-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');

  .x-shop-header {
    background: white;
    x-header-layout {
      position: relative;
      z-index: 2;
      color: map-get($custom-colors, shop-header-color);

      x-logged-in,
      x-logged-out,
      x-order-bag-button {
        .mat-mdc-button-base {
          color: map-get($custom-colors, shop-header-color);
        }
      }

      .actions-right {
        [xgohomelink],
        [xredirectback] {
          color: map-get($custom-colors, shop-header-color);
        }
      }

      header {
        height: 50px;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
          .actions-right {
            margin-right: 3px;
            .mat-mdc-button-base {
              width: 35px;
            }
          }
        }
      }
    }

    &.accent {
      background: mat.get-color-from-palette(map-get($theme, accent), 100);
    }

    &.custom {
      @include x-shop-header-custom($theme-config);
    }

    &.custom-mobile {
      @include media-breakpoint-down(lg) {
        @include x-shop-header-custom($theme-config);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .x-shop-header {
      .actions-right {
        .mat-mdc-button-base {
          height: 28px;
          line-height: 28px;
        }
      }
    }
  }
}
