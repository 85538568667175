@mixin x-brand-logo-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-brand-logo {
    @include x-brand-logo;

    svg {
      fill: map-get($custom-colors, brand-logo-fill);
    }
  }
}
