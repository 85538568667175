@use '@angular/material' as mat;

@mixin x-auth-flow-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-auth-flow {
    .x-auth-flow-body {
      x-close-button {
        position: sticky;
        top: 50px;
        background: white;
        z-index: 2;
      }

      .x-brand-logo {
        height: 100px;
        margin: auto;
        display: flex;
      }

      .form {
        display: flex;
        justify-content: center;
        margin: auto;

        .newletter-optin {
          margin-top: 20px;
          margin-bottom: 15px;

          .mdc-form-field {
            align-items: flex-start;

            > label {
              margin-top: 7px;
              @include mat.typography-level($typography, display-2);
            }
          }
        }

        .secondary-auth-action {
          margin-top: 20px;
        }

        .need-help-action {
          margin-top: 4px;
          height: 30px;
        }

        @include media-breakpoint-down(lg) {
          padding-bottom: 120px;
          max-width: 400px;
        }
      }

      .authentication-actions {
        padding-top: 10px;

        .buttons {
          display: flex;
          gap: 10px;

          button {
            flex: 1;
          }
        }
      }

      .auth-section {
        display: flex;
        flex-direction: column;

        max-width: 400px;
        padding: 0px 20px;

        flex: 1 1 0;

        @include media-breakpoint-up(md) {
          width: 400px;
        }
      }

      .page-content {
        display: flex;
        margin-top: 28px;
        gap: 20px;
        hr {
          border-left: 1px solid map-get($custom-colors, borders);
        }

        @include media-breakpoint-down(lg) {
          margin-top: 0px;
          flex-direction: column;
          hr {
            border-top: 1px solid map-get($custom-colors, borders);
            width: 100%;
          }
        }
      }
    }

    x-shop-layout {
      > footer {
        @include media-breakpoint-down(lg) {
          display: none;
        }
        display: flex;
        justify-content: center;
        position: sticky;
        bottom: 0;
        margin-top: 40px;
        z-index: 2;

        .secure {
          display: flex;
          width: fit-content;
          background-color: white;
          padding: 8px;
          border-radius: 0.6em 0.6em 0 0;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
