@use '@angular/material' as mat;

@mixin x-colors($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $border-color: map-get($custom-colors, borders);

  //primary colors
  .bg-color-primary-500 {
    background: mat.get-color-from-palette(map-get($theme, primary), 500);
    color: mat.get-color-from-palette(map-get($theme, primary), '500-contrast');
  }

  // accent colors
  .bg-color-accent-100 {
    background: mat.get-color-from-palette(map-get($theme, accent), 100);
    color: mat.get-color-from-palette(map-get($theme, accent), '100-contrast');
  }

  .bg-color-accent-200 {
    background: mat.get-color-from-palette(map-get($theme, accent), 200);
    color: mat.get-color-from-palette(map-get($theme, accent), '200-contrast');
  }

  .bg-color-accent-300 {
    background: mat.get-color-from-palette(map-get($theme, accent), 300);
    color: mat.get-color-from-palette(map-get($theme, accent), '300-contrast');
  }

  .border-top-color {
    border-top: 1px solid $border-color;
  }
  .border-bottom-color {
    border-bottom: 1px solid $border-color;
  }
  .border-left-color {
    border-left: 1px solid $border-color;
  }
  .border-right-color {
    border-right: 1px solid $border-color;
  }
  .border-all-color {
    border: 1px solid $border-color;
  }
}
