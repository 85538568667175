@mixin x-flag-icon-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .flag {
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
