@use '@angular/material' as mat;

@mixin x-checkout-summary-order-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $warn-color: mat.get-color-from-palette(map-get($theme, warn), 500);
  $typography: map-get($theme-config, typography);

  .x-checkout-summary-order {
    .tables {
      display: flex;
      flex-direction: column;
      gap: 5px;

      table {
        width: 100%;
        border-collapse: collapse;
      }
    }

    .order-item {
      td {
        padding: 0 10px;
        text-align: right;
        white-space: nowrap;
      }

      td.image {
        padding: 0;

        a {
          display: block;
          width: 50px;
        }
      }

      td.name-cell {
        width: 100%;
        text-align: left;

        .name {
          display: flex;
          flex-direction: column;
          gap: 5px;

          @include media-breakpoint-down(lg) {
            max-width: 120px;
          }

          .product-name {
            white-space: pre-wrap;
            line-height: normal;
          }
        }
      }

      td:last-child {
        padding-right: 0;
      }
    }

    .warning-wrapper {
      background: rgba($warn-color, 0.1);
      padding: 10px;

      table {
        td {
          padding: 3px !important;
        }
      }

      .msg {
        color: mat.get-color-from-palette(map-get($theme, warn), 500);
        line-height: normal;
        @include mat.typography-level($typography, caption);
      }

      .action {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: space-between;
      }

      .order-item {
        td:last-child {
          padding-right: 5px;
        }
      }
    }
  }
}
