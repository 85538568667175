@use '@angular/material' as mat;

@mixin x-delivery-calendar-swiper-theme($theme-config) {
  $typography: map-get($theme-config, typography);
  .x-delivery-calendar-swiper {
    .calendar-swiper-container {
      margin-bottom: 2rem;
      border: 1px solid get-palette-color($theme-config, accent, 400);
      padding: 20px;

      .month-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .month-title {
          @include mat.typography-level($typography, headline-6);
          text-transform: uppercase;
        }

        .month-controls {
          display: flex;
          gap: 0.5rem;
        }
      }

      .calendar-month {
        .weekday-headers {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          text-align: center;
          margin-bottom: 0.5rem;
          gap: 10px;
          @include mat.typography-level($typography, headline-6);

          .weekday-header {
            padding: 8px 12px;
            text-transform: uppercase;
            text-align: right;

            @include media-breakpoint-down(md) {
              text-align: center;
              padding: 8px 0;
            }
          }
        }

        .calendar-days {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          gap: 10px;

          @include media-breakpoint-down(md) {
            gap: 8px;
          }

          .calendar-day {
            border-radius: 4px;
            padding: 8px 12px;
            display: flex;
            flex-direction: column;
            position: relative;
            cursor: default;
            transition: all 0.2s ease;
            border: none;
            overflow: hidden;
            height: 60px;

            &.other-month {
              pointer-events: none;

              // Allow available/partially-available days to be clickable even in other months
              &.available,
              &.partially-available {
                cursor: pointer;
                pointer-events: auto;
              }
            }

            // States

            // No Slot - No slots on the given date
            &.no-slot {
              border: none;
              background-color: get-custom-color($theme-config, calendar-day-unavailable-bg);
              cursor: not-allowed;

              .day-number,
              .slot-state {
                color: get-custom-color($theme-config, calendar-day-unavailable-text);
              }
            }

            // Available - Full order can be delivered
            &.available {
              border: none;
              background-color: get-custom-color($theme-config, calendar-day-available-bg);
              cursor: pointer;

              .day-number,
              .slot-state {
                color: get-custom-color($theme-config, calendar-day-available-text);
              }

              &:hover,
              &.selected {
                background-color: get-custom-color($theme-config, calendar-day-available-hover-bg);

                .day-number,
                .slot-state {
                  color: white;
                }
              }
            }

            // Partially Available - Order can be partially delivered
            &.partially-available {
              border: none;
              background-color: get-custom-color(
                $theme-config,
                calendar-day-partially-available-bg
              );
              cursor: pointer;

              &:hover,
              &.selected {
                background-color: get-custom-color(
                  $theme-config,
                  calendar-day-partially-available-hover-bg
                );
              }

              .day-number,
              .slot-state {
                color: get-custom-color($theme-config, calendar-day-partially-available-text);
              }
            }

            // Fully Booked - No timeslots available
            &.fully-booked {
              border: none;
              background-color: get-custom-color($theme-config, calendar-day-fully-booked-bg);
              cursor: not-allowed;

              .day-number,
              .slot-state {
                color: get-custom-color($theme-config, calendar-day-fully-booked-text);
              }
            }

            // Unavailable - Cannot be delivered for other reasons
            &.unavailable {
              border: none;
              background-color: get-custom-color($theme-config, calendar-day-unavailable-bg);
              cursor: not-allowed;

              .day-number,
              .slot-state {
                color: get-custom-color($theme-config, calendar-day-unavailable-text);
              }
            }

            .day-number {
              align-self: flex-end;
              @include mat.typography-level($typography, subtitle-2);
            }

            .slot-state {
              margin-top: auto;
              align-self: flex-start;
              text-align: left;
              @include mat.typography-level($typography, caption);
              font-weight: 400;
              width: 100%;
            }

            @include media-breakpoint-down(md) {
              aspect-ratio: 1/1;
              padding: 10px;
              align-items: center;
              justify-content: center;
              flex-direction: unset;
              height: unset;

              .day-number {
                align-self: unset;
                @include mat.typography-level($typography, display-2);
                font-weight: 300;
              }

              .slot-state {
                display: none;
              }
            }
          }
        }
      }
    }

    .calender-keys {
      display: none;
      flex-direction: column;
      gap: 5px;

      .calender-key {
        display: flex;
        align-items: center;
        gap: 5px;
        @include mat.typography-level($typography, caption);

        .key-color {
          width: 12px;
          aspect-ratio: 1/1;

          &.available {
            background-color: get-custom-color($theme-config, calendar-day-available-hover-bg);
          }

          &.partially-available {
            background-color: get-custom-color(
              $theme-config,
              calendar-day-partially-available-hover-bg
            );
          }

          &.fully-booked {
            background-color: get-custom-color($theme-config, calendar-day-fully-booked-bg);
          }
        }
      }

      @include media-breakpoint-down(md) {
        display: flex;
      }
    }
  }
}
