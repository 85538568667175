@use '@angular/material' as mat;
@mixin x-footer-branding-theme($theme-config) {
  .x-footer-branding {
    .brand-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .brand-logo {
        height: 90px;
        aspect-ratio: 1/1;
      }

      .brand-links {
        display: flex;
        justify-content: center;
        gap: 50px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-footer-branding {
      .brand-section {
        .brand-links {
          align-items: center;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }
}
