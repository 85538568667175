@use '@angular/material' as mat;

@mixin shared($theme) {
  $theme: map-get($theme-config, theme);
  $foreground: map-get($theme, 'foreground');
  $background: map-get($theme, 'background');
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');

  .background {
    &-transparent {
      background-color: transparent;
    }
    &-light {
      background-color: mat.get-color-from-palette($accent, 100);
    }
    &-primary {
      background-color: mat.get-color-from-palette($primary, 100);
    }
    &-accent {
      background-color: mat.get-color-from-palette($accent, 300);
    }
    &-warn {
      background-color: mat.get-color-from-palette($warn, 100);
    }
  }
}
