@use '@angular/material' as mat;

@mixin x-swiper-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .x-swiper {
    position: relative;
    display: block;

    swiper-container::part(button-next),
    swiper-container::part(button-prev),
    swiper-container::part(scrollbar) {
      display: none;
    }

    .nav {
      position: absolute;
      height: 40px;
      top: 50%;
      width: 40px;
      margin-top: -20px;
      background-color: mat.get-color-from-palette(map-get($theme, accent), 500);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
      z-index: 2;
      opacity: 0.5;
      transition: opacity ease-in 300ms;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        mask-size: 40%;
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: mat.get-color-from-palette(map-get($theme, accent), 500-contrast);
      }

      &.swiper-button-disabled {
        cursor: not-allowed;
        opacity: 0;
      }

      &.swiper-button-prev {
        left: 0;

        &:after {
          mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path  d="M32.5,48c-0.4,0-0.8-0.1-1.1-0.4L10,26.1c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.8,0.3-1.6,0.9-2.1L31.4,0.4  c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1L12.1,24l21.4,21.4c0.6,0.6,0.6,1.5,0,2.1C33.3,47.9,32.9,48,32.5,48z" ></path></svg>');
        }
      }

      &.swiper-button-next {
        right: 0;
        &:after {
          mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48"><path  d="M11,48c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1L31.4,24L9.9,2.6C9.4,2,9.4,1,9.9,0.4s1.5-0.6,2.1,0  l21.4,21.4c0.6,0.6,0.9,1.3,0.9,2.1c0,0.8-0.3,1.6-0.9,2.1L12.1,47.6C11.8,47.9,11.4,48,11,48z" ></path></svg>');
        }
      }
    }

    &:hover {
      .nav:not(.swiper-button-disabled) {
        opacity: 1;
        cursor: pointer;
      }
    }

    .ssr-slides {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
    }
  }
}
