@use '@angular/material' as mat;

@mixin x-scroll-bar-extensions($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $theme: map-get($theme-config, theme);

  // Not a real material override, Naude please advise where something like this should be
  @supports selector(::-webkit-scrollbar) {
    *::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

    /* Track */
    *::-webkit-scrollbar-track {
      background: white;
    }

    /* Handle */
    *::-webkit-scrollbar-thumb {
      background: mat.get-color-from-palette(map-get($theme, accent), 100);
      border-radius: 0.3em;
    }

    /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
      background: mat.get-color-from-palette(map-get($theme, accent), 100);
    }
  }
}
