@mixin x-ghost-loading-theme($theme-config) {
  // ghost theming
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $ghost-bg: rgba(map-get($custom-colors, bg-dark), 0.1);

  .ghost-add-to-cart-container {
    .price {
      background: $ghost-bg;
    }

    .action {
      background: $ghost-bg;
    }
  }

  .ghost-product-media {
    background-color: $ghost-bg;
  }

  .x-ghost-media {
    background-color: $ghost-bg;
  }

  .ghost-paragraphs {
    .paragraph {
      background: $ghost-bg;
    }
  }

  .ghost-title {
    background: $ghost-bg;
  }

  .x-ghost-table {
    thead {
      .head {
        background: $ghost-bg;
      }
    }
    tbody {
      tr {
        background: $ghost-bg;
      }
    }
  }

  .x-ghost-button {
    button {
      background: $ghost-bg;
    }
  }

  .x-ghost-section-one {
    background: $ghost-bg;
  }
}
