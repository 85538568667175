@use '@angular/material' as mat;
@mixin x-mat-field-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .mat-mdc-input-element {
    border: none;
  }

  .mat-mdc-form-field,
  .mat-mdc-floating-label {
    font-weight: 300;
  }

  //input with suffix icon
  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    position: relative;
    padding: 0 10px;
  }

  //label with icon
  .mat-mdc-floating-label {
    fa-icon {
      position: relative;
      top: 1px;
    }
  }

  .mat-mdc-form-field {
    .mdc-text-field {
      font-size: 16px; // 16px to prevent ios zoom
      background: none;
      border: 1px solid map-get($custom-colors, input-borders);
      border-radius: $fields-border-radius;

      &.mdc-text-field--focused {
        border: 1px solid mat.get-color-from-palette(map-get($theme, primary), 300);
      }

      &.mdc-text-field--invalid {
        border: 1px solid mat.get-color-from-palette(map-get($theme, warn), 500);

        .mdc-line-ripple {
          display: none;
        }
      }
    }
  }

  .mdc-text-field:not(.mdc-text-field--invalid) {
    .mdc-line-ripple {
      display: none;
    }
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  // no-fill
  .mat-mdc-form-field.no-fill {
    .mdc-text-field--filled {
      border: none !important;
      background: none !important;
    }
  }

  // no-padding
  .mat-mdc-form-field.no-padding {
    .mdc-text-field--filled {
      padding: 0;
    }
  }
}
