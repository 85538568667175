@use '@angular/material' as mat;

@mixin x-select-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .mat-mdc-select-arrow-wrapper {
    mask: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M24,38c-0.8,0-1.6-0.3-2.1-0.9L0.4,15.7c-0.6-0.6-0.6-1.5,0-2.1C1,13,2,13,2.6,13.6L24,35l21.4-21.4c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1L26.1,37.1C25.6,37.7,24.8,38,24,38C24,38,24,38,24,38z"/></svg>')
      no-repeat center;
    height: 10px;
    background: black;
    transform: none !important;
    .mat-mdc-select-arrow {
      opacity: 0;
    }
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    padding: 0;
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled),
  .mat-mdc-option:focus:not(.mdc-list-item--disabled),
  .mat-mdc-option.mat-mdc-option-active,
  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
      .mdc-list-item--disabled
    ) {
    background: mat.get-color-from-palette(map-get($theme, accent), 200);
  }

  .mdc-menu-surface {
    box-shadow: $drop-shadow;
  }

  .mat-mdc-select,
  .mat-mdc-option {
    @include mat.typography-level($typography, body-2);
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .mat-mdc-option {
    .mdc-list-item__primary-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mdc-menu-surface.mat-mdc-autocomplete-panel {
    padding: 1px 0 0;
  }
}
