@use '@angular/material' as mat;
@mixin x-checkout-page-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-checkout-page {
    .x-shop-layout {
      min-height: 100%;

      .x-header-layout {
        .redirect-back {
          span {
            @include mat.typography-level($typography, title-case-link);
          }
        }
      }
    }

    /*sticky positions*/
    .checkout-stepper-header-wrapper {
      border-bottom: 1px solid map-get($custom-colors, borders);
      position: sticky;
      top: 0;
      background: white;
      z-index: 2;
    }

    .x-checkout-summary-section-order-totals {
      position: sticky;
      top: 138px;
      background: white;
      z-index: 2;
    }

    .x-checkout-steps {
      padding-top: 20px;
    }

    .step-action {
      .total {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-checkout-page {
      .x-shop-layout {
        padding: 0 0 170px 0;
        min-height: 100%;

        /*step actions bottom footer*/
        .step-action {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          background: white;
          z-index: 2;
          padding: 10px 10px 0 10px;
          border-top: 1px solid map-get($custom-colors, borders);
          .total {
            display: block;
          }
          button {
            height: 50px;
            &.continue {
              .mdc-button__label {
                width: 100%;
                display: flex;
                justify-content: space-between;
              }
            }
          }

          .need-help-action {
            height: 40px;
          }
        }

        .x-checkout-summary {
          .x-checkout-summary-section-order-totals {
            position: unset;
          }
        }
      }

      .x-delivery-step-action {
        button {
          &.continue {
            .mdc-button__label {
              .total {
                display: none;
              }
              justify-content: center !important;
            }
          }
        }
      }
    }
  }
}
