@use '@angular/material' as mat;
@mixin x-checkout-order-table-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);
  $warn-color: mat.get-color-from-palette(map-get($theme, warn), 500);

  .order-adjustment {
    color: map-get($custom-colors, heavy-success);
  }

  .x-checkout-order-table {
    table {
      width: 100%;
      border-collapse: collapse;

      tbody + thead:before {
        content: '\00a0';
        display: block;
        line-height: 1em;
      }

      thead {
        tr {
          height: 45px;

          th {
            @include mat.typography-level($typography, body-2);
            color: map-get($custom-colors, body-color-1);
          }
        }

        &.thead-border {
          th {
            bottom: 1px solid map-get($custom-colors, borders);
          }
        }
      }

      th {
        text-align: left;
      }

      tbody {
        td.thumbnail {
          width: 100px;
          padding-right: 20px;
        }

        &.warning {
          background: rgba($warn-color, 0.1);

          td.thumbnail {
            padding-left: 10px;
          }

          .msg {
            @include mat.typography-level($typography, caption);
          }

          .msg,
          .action {
            padding: 5px;
            color: $warn-color;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          }
        }
      }
    }

    .order-items {
      header {
        display: grid;
        grid-template-columns: 4fr 1fr 1fr 1fr 100px;
        gap: 10px;
        align-items: center;
      }
    }
  }
}
