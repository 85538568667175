@use '@angular/material' as mat;

@import 'components/subscriber-checkout-summary/subscriber-checkout-summary.theme';

@mixin x-subscriber-checkout-theme($theme-config) {
  @include x-subscriber-checkout-summary-theme($theme-config);

  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .requirement {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .requirement-error {
    padding: 10px;
    background: lighten(mat.get-color-from-palette(map-get($theme, warn), 50), 2%);

    &-message {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      line-height: normal;
    }
  }

  .x-subscriber-checkout-page,
  .x-subscriber-success-page {
    .x-shop-layout {
      min-height: 100%;

      x-subscriber-checkout-header {
        position: sticky;
        top: 50px;
        z-index: 1;
        background: white;
        border-bottom: 1px solid map-get($custom-colors, borders);
      }

      .subscription-summary-panel {
        position: sticky;
        top: 142px;
        z-index: 1;
      }

      .totals {
        font-size: 20px;
        letter-spacing: 0.1em;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .total {
        display: inline-flex;
        .interval {
          &::before {
            display: inline-block;
            content: '\0000a0';
          }
          font-size: 0.875rem;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-subscriber-checkout-page {
      .x-shop-layout {
        padding: 0 0 170px 0;
        height: 100%;

        x-subscriber-checkout-header {
          top: 0;
          padding: 10px;

          .container {
            padding: 0;
          }
        }

        .mobile-footer {
          position: fixed;
          z-index: 1;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 10px 10px 0;
          border-top: 1px solid map-get($custom-colors, borders);
          background: white;

          button.has-totals {
            .mdc-button__label {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            }
          }

          .need-help-action {
            height: 40px;
          }
        }
      }
    }
  }
}
