@use '@angular/material' as mat;
@mixin x-icon-ui-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  //default
  @include iconSize();
  svg.svg-inline--fa {
    vertical-align: unset;
  }

  fa-icon {
    display: inline-flex;

    &.icon-size-x-large {
      @include iconSize(1.56rem);
    }

    &.icon-size-large {
      @include iconSize(1.3rem);
    }

    &.icon-size-medium {
      @include iconSize(1.2rem);
    }

    &.icon-size-small {
      @include iconSize(0.9rem);
    }

    &.icon-size-x-small {
      @include iconSize(0.7rem);
    }

    &.icon-size-xx-small {
      @include iconSize(0.6rem);
    }
  }

  //icons colors
  .icon-color-primary {
    color: mat.get-color-from-palette(map-get($theme, primary), 500);
  }
  .icon-color-accent {
    color: mat.get-color-from-palette(map-get($theme, accent), 500);
  }
  .icon-color-warn {
    color: mat.get-color-from-palette(map-get($theme, warn), 500);
  }
  .icon-color-grey {
    color: mat.get-color-from-palette($custom-colors, icon-grey);
  }

  .icon-status {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.success {
      border: 1px solid mat.get-color-from-palette(map-get($theme, primary), 500);
      color: mat.get-color-from-palette(map-get($theme, primary), 500);
    }

    &.fail {
      border: 1px solid mat.get-color-from-palette(map-get($theme, warn), 500);
      color: mat.get-color-from-palette(map-get($theme, warn), 500);
    }
  }

  //responsive
  @include media-breakpoint-down(lg) {
    @include iconSize(1.1rem);
  }
}

@mixin iconSize($size: 1rem) {
  svg.svg-inline--fa {
    width: $size;
    height: $size;
  }
}
