@use '@angular/material' as mat;

@mixin x-action-banner-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $background: map-get($theme, 'background');

  .x-action-banner {
    .action-banner-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-width: 100%;
      @include media-breakpoint-up(sm) {
        min-width: 500px;
      }
      a:not(.x-button) {
        width: 100%;
        height: 100%;
      }

      .x-button {
        position: absolute;
        margin: auto;
        color: white;
        z-index: 1;
        font-weight: bold;
        font-size: 19px;
        margin: 16px;
        display: inline-block;
        white-space: normal;
        text-align: left;
        line-height: 1.5;
        @include media-breakpoint-up(md) {
          font-size: 26px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 32px;
        }
      }
    }
  }
}
