@use '@angular/material' as mat;

@mixin x-order-totals-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .order-adjustment {
    color: map-get($custom-colors, heavy-success);
  }

  .x-order-totals {
    color: map-get($custom-colors, body-color-2);

    .paid-total {
      color: mat.get-color-from-palette(map-get($theme, primary), 500);
    }

    .total-outstanding {
      color: mat.get-color-from-palette(map-get($theme, warn), 500);
    }
  }
}
