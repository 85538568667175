@use '@angular/material' as mat;

@mixin x-bag-step-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-bag-step {
    .promo-code {
      .mat-mdc-text-field-wrapper {
        position: relative;
        overflow: hidden;

        .mat-mdc-form-field-icon-suffix {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          padding: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-bag-step {
      .mat-expansion-panel-body {
        padding: 10px;
      }
      .order-summary-expansion {
        .mat-expansion-panel-body {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 0;

          x-checkout-summary-delivery,
          x-order-totals {
            padding-top: 10px;
            border-top: 1px solid map-get($custom-colors, borders);
          }
        }
      }
    }
  }
}
