@use '@angular/material' as mat;

@mixin x-footer-link-list-theme($theme-config) {
  x-footer-link-list {
    border-bottom: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    display: block;
    padding-top: 50px;
    padding-bottom: 50px;

    .container-lg {
      display: flex;
    }
    .footer-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 25%;
    }

    @include media-breakpoint-down(lg) {
      .footer-menu {
        gap: 15px 0;

        menu {
          flex-basis: 50%;
        }
      }
    }

    .mat-expansion-panel {
      border: none !important;
      text-align: center;
      ul {
        padding-inline-start: 0px;
        list-style: none;
        li {
          padding-bottom: 12px;
        }
        a {
          align-items: center;
          gap: 10px;
          cursor: pointer;

          &:hover {
            color: mat.get-color-from-palette(map-get($theme, primary), 500);
          }

          div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          fa-icon {
            margin-right: 8px;
          }
        }
      }
      .mat-expansion-panel-header {
        display: flex;
        text-align: center;
        background-color: transparent !important;
        width: fit-content;
        margin: 0px auto;

        .mat-content {
          flex: none;
        }
        &.mat-expanded {
          background-color: transparent !important;
        }
        &:hover {
          background-color: transparent !important;
        }
      }

      &:not(.mat-expanded) {
        background-color: transparent !important;
      }
    }

    .footer-menu {
      ul,
      li {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      a {
        padding: 5px 0;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &:hover {
          color: mat.get-color-from-palette(map-get($theme, primary), 500);
        }
      }
    }

    .label {
      text-transform: uppercase;
    }
  }
}
