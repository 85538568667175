@import 'components/order-history-detail-page/order-history-detail-page.theme';
@import 'components/payment-credit-page/payment-credit-page.theme';
@import 'components/subscriptions-page/subscriptions-page.theme';
@import 'components/vouchers-page/vouchers-page.theme';
@import 'components/order-history-page/order-history-page.theme';

@mixin x-my-account-theme($theme-config) {
  @include x-order-history-detail-page-theme($theme-config);
  @include x-payment-credit-page-theme($theme-config);
  @include x-subscriptions-page-theme($theme-config);
  @include x-vouchers-page-theme($theme-config);
  @include x-order-history-page-theme($theme-config);

  x-my-account-page {
    x-my-account-menu {
      position: sticky;
      top: 125px;
    }
  }
}
