@use '@angular/material' as mat;

@mixin x-event-listing-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');

  .x-event-listing {
    .background {
      &-transparent {
        background-color: transparent;
      }
      &-light {
        background-color: mat.get-color-from-palette($background, 'light');
        color: mat.get-color-from-palette($foreground, 'light');
      }
      &-primary {
        background-color: mat.get-color-from-palette($primary);
        color: white;
      }
      &-accent {
        background-color: mat.get-color-from-palette($accent);
        color: white;
      }
      &-warn {
        background-color: mat.get-color-from-palette($warn);
        color: white;
      }
    }
    .event-listing-item {
      a {
        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
        h3 {
          margin-bottom: 8px;
        }
        img {
          margin-bottom: 16px;
          height: auto;
          max-width: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .x-button {
        margin: 0;
      }
    }

    .background {
      &-transparent {
        background-color: transparent;
      }
      &-light {
        background-color: mat.get-color-from-palette($accent, 100);
      }
      &-primary {
        background-color: mat.get-color-from-palette($primary, 100);
      }
      &-accent {
        background-color: mat.get-color-from-palette($accent, 300);
        // color: white;
      }
      &-warn {
        background-color: mat.get-color-from-palette($warn, 100);
      }
    }
  }
}
