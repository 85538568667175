@use '@angular/material' as mat;

@mixin x-optgroup-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  mat-optgroup {
    background: mat.get-color-from-palette(map-get($theme, accent), 100);
    display: block;
    .mdc-list-item {
      background-color: unset;
    }
    .mat-mdc-optgroup-label {
      @include mat.typography-level($typography, body-1);
      color: mat.get-color-from-palette(map-get($theme, primary));
    }
  }
}
