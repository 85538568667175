@use '@angular/material' as mat;

@mixin x-sidebar-layout-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-sidebar-layout {
    .sidebar {
      background: get-custom-color($theme-config, sidebar-bg);

      > .menu {
        padding: 15px 0;
      }

      > .content {
        background: white;
      }

      > .footer {
        border-top: 1px solid map-get($custom-colors, borders);
        background: #f4f4f4;

        x-channel-picker {
          background: white;
          padding: 10px 16px;
          display: block;
          .mat-mdc-select-arrow-wrapper {
            background: black;
          }
        }

        .footer-actions {
          display: flex;
          align-items: center;
          justify-content: space-between;

          button {
            padding: 0 16px;
          }
        }
      }

      > header {
        button.mat-mdc-button {
          width: 100%;
          padding: 0 16px;
          .mat-button-wrapper {
            justify-content: flex-start;
            gap: 5px;
          }
        }
      }
    }
  }
}
