@use '@angular/material' as mat;

@mixin x-product-view-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-product-view {
    display: block;

    x-wishlist-action {
      .mat-mdc-button-base {
        width: 40px;
        height: 40px;
      }
    }

    x-product-social-button {
      .mat-mdc-button-base {
        width: 40px;
        height: 40px;
      }
    }

    .product-detail {
      @include media-breakpoint-up(lg) {
        padding-left: 40px;
      }

      header > h1 {
        @include mat.typography-level($typography, headline-2);
      }

      > .add-to-cart {
        width: 300px;

        .select-button {
          padding: 0 34px;
        }
      }
    }

    .x-product-options-form {
      .mat-button-toggle .mat-button-toggle-label-content {
        @include mat.typography-level($typography, display-2);
      }

      .mat-button-toggle-group {
        gap: 10px;

        mat-button-toggle {
          &.mat-button-toggle-appearance-standard {
            border: 1px solid mat.get-color-from-palette(map-get($theme, accent), 300) !important;
          }

          &.mat-button-toggle-checked {
            border: 1px solid mat.get-color-from-palette(map-get($theme, primary), 500) !important;
          }
        }
      }
    }

    .gallery-view-toggle {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      padding: 0.1rem !important;
      background-color: mat.get-color-from-palette(map-get($theme, accent), 200);
      border-radius: 5rem;
      margin: 15px;
      @include media-breakpoint-down(lg) {
        margin: 10px;
      }
      cursor: pointer;
      width: 40px;
      height: 40px;

      svg.svg-inline--fa {
        width: 1.1rem;
        height: 1.1rem;
      }
    }

    .product-media {
      position: relative;
    }

    .page-actions-row {
      background: white;
    }

    .page-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .product-detail {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .price-and-avail {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
    }

    x-product-availaility {
      height: 30px;

      &:empty {
        display: none;
      }
    }

    .add-to-cart {
      display: flex;
    }

    .product-add-to-cart-mobile {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      z-index: 1;

      .variant-selection {
        padding: 20px;
      }

      .price-and-add-to-cart {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 15px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .x-product-view {
      .page-actions-row {
        position: static;
      }

      .product-detail > header h1 {
        @include mat.typography-level($typography, headline-2);
      }

      .product-add-to-cart-mobile {
        display: none;
        border-top: 1px solid map-get($custom-colors, mobile-add-to-cart-border-top-color);
        background-color: map-get($custom-colors, bg-light);

        .price-and-add-to-cart {
          background-color: map-get($custom-colors, mobile-add-to-cart-bg-color);
        }
      }

      x-product-cross-sell x-product-list-item .action {
        display: none;
      }

      &.sticky-action-bar {
        .product-add-to-cart-mobile {
          display: block;
        }

        .product-detail {
          x-product-price,
          x-product-variant-selection,
          x-product-add-to-cart,
          .add-to-cart,
          .see-options {
            display: none;
          }
        }
      }
    }
  }
}
