@use '@angular/material' as mat;

@mixin x-add-to-cart-overlay-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-add-to-cart-overlay {
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    overflow: hidden;
    background: white;

    @include media-breakpoint-down(lg) {
      top: -12px;
    }

    @include media-breakpoint-down(md) {
      width: 95%;
    }

    .order-item {
      display: flex;
      align-items: center;
      overflow: hidden;

      x-media-thumbnail {
        width: 65px;
        flex-shrink: 0;
      }
      .info {
        padding: 5px 10px;
        flex-shrink: 1;
        overflow: hidden;

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .loader {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 5px;
      width: 100%;
      background: mat.get-color-from-palette(map-get($theme, primary), 500);
      transition: width 300ms ease;
    }
  }

  .add-to-cart-overlay-pane {
    @include media-breakpoint-down(md) {
      justify-content: center;
      width: 100%;
    }
  }
}
