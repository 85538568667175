@use '@angular/material' as mat;
@mixin x-footer-menus-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-footer-menus {
    .footer-menus {
      display: flex;
      flex-wrap: wrap;

      x-footer-menu {
        flex-basis: 25%;
      }
    }

    @include media-breakpoint-down(lg) {
      .footer-menus {
        gap: 15px 0;

        x-footer-menu {
          flex-basis: 50%;
        }
      }
    }

    .footer-menu-accordion {
      margin: 1.5em;

      .mat-expansion-panel {
        border: none;
        text-align: center;
        ul {
          padding-inline-start: 0px;
          list-style: none;
          li {
            padding-bottom: 12px;
          }
          a {
            align-items: center;
            gap: 10px;
            cursor: pointer;

            &:hover {
              color: mat.get-color-from-palette(map-get($theme, primary), 500);
            }

            div {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            fa-icon {
              margin-right: 8px;
            }
          }
        }
        .mat-expansion-panel-header {
          display: flex;
          text-align: center;
          background-color: transparent !important;
          width: fit-content;
          margin: 0px auto;

          .mat-content {
            flex: none;
          }
        }
        &:not(.mat-expanded) {
          background-color: transparent !important;
        }
      }
    }
  }

  .x-footer-menu {
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    a {
      padding: 5px 0;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      &:hover {
        color: mat.get-color-from-palette(map-get($theme, primary), 500);
      }
    }
  }
}
