@use '@angular/material' as mat;
@mixin x-checkout-reguirement-feedback-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-checkout-reguirement-feedback {
    &:empty {
      display: none;
    }

    .requirements {
      background: lighten(mat.get-color-from-palette(map-get($theme, warn), 50), 2%);
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;

      .message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        .msg {
          line-height: normal;
        }

        .actions {
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }
}
