@use '@angular/material' as mat;

@mixin x-section-with-image-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $foreground: map-get($theme, 'foreground');
  $background: map-get($theme, 'background');

  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');

  .x-section-with-image {
    .media {
      x-content-media {
        height: 100%;
        width: 100%;
      }
    }
    .content {
      max-width: 600px;

      h2 {
        margin-bottom: 1rem;
      }

      .x-button {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .layout-pos {
      &-end {
        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }
        @include media-breakpoint-down(lg) {
          flex-direction: column;
        }
      }
      &-start {
        @include media-breakpoint-up(lg) {
          flex-direction: row-reverse;
        }
        @include media-breakpoint-down(lg) {
          flex-direction: column;
        }
      }
    }

    .x-markdown-view {
      h6 {
        margin-bottom: 10px;
      }
    }
  }
}
