@use '@angular/material' as mat;

@mixin x-spacer-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  $background: map-get($theme, 'background');
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');

  .x-spacer {
    .size_10 {
      height: 10px;
    }

    .size_30 {
      height: 30px;
    }

    .size_50 {
      height: 50px;
    }

    .size_100 {
      height: 100px;
    }
  }
}
