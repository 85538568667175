@use '@angular/material' as mat;

@mixin x-textarea-autosave-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-textarea-autosave {
    mat-form-field {
      width: 100%;
    }

    header {
      margin-bottom: 5px !important;
    }

    p {
      margin-bottom: 20px;
    }

    .textarea-with-action {
      display: flex;
      flex-direction: column;

      .actions {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        small {
          color: map-get($custom-colors, body-color-1);
        }
      }
    }

    .save-state {
      display: flex;
      align-items: center;
      gap: 3px;
      padding: 0 7px;

      fa-icon {
        position: relative;
        top: -1px;
      }

      &.saved {
        background: map-get($custom-colors, success);
        color: map-get($custom-colors, region-button-selected-color);
      }
      &.saving {
        background: mat.get-color-from-palette(map-get($theme, accent), 500);
        color: mat.get-color-from-palette(map-get($theme, accent), 500-contrast);
      }
    }
  }
}
