@use '@angular/material' as mat;
@mixin x-button-basic-extensions($theme-config) {
  .mat-mdc-button-base {
    border-radius: $buttons-border-radius;
    height: 48px;

    .mdc-button__label {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: center;
      height: 100%;
      max-width: 100%;
      -webkit-font-smoothing: subpixel-antialiased;
      white-space: nowrap;
    }

    /*disable hover*/
    .mat-mdc-button-persistent-ripple {
      display: none;
    }

    &[mat-flat-button],
    &[mat-stroked-button] {
      text-transform: uppercase;
    }
  }
}
