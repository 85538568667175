@use '@angular/material' as mat;

/*mixins*/
@mixin shop-grid-layout($cols, $gap: 0, $gap-height: $gap) {
  display: grid;
  gap: $gap-height $gap;
  grid-template-columns: repeat($cols, 1fr);
}

@mixin x-shop-header-custom($theme-config) {
  $theme: map-get($theme-config, theme);
  background: mat.get-color-from-palette(map-get($theme, accent), 100);
}

@mixin x-brand-logo {
  height: 12px;
  @include media-breakpoint-down(lg) {
    height: 11px;
  }
}

/*vars*/
$drop-shadow: 0 3px 2px rgba(black, 0.1);
$buttons-border-radius: 4px;
$fields-border-radius: 2px;

/*functions*/
@function get-custom-color($theme-config, $color-name) {
  $custom-config: map-get($theme-config, 'custom');
  $custom-colors: map-get($custom-config, 'colors');
  @return map-get($custom-colors, $color-name);
}

@function get-palette-color($theme-config, $palette, $hue) {
  $theme: map-get($theme-config, theme);
  @return mat.get-color-from-palette(map-get($theme, $palette), $hue);
}

@mixin get-font($theme-config, $typography-name) {
  $typography: map-get($theme-config, typography);
  @include mat.typography-level($typography, $typography-name);
}

@mixin order-item-expand-grid {
  display: grid;
  grid-template-columns: 4fr 1fr 2fr 1fr 100px;
  gap: 10px;
  align-items: center;
  > *:not(:first-child) {
    justify-self: center;
  }
}
