@use '@angular/material' as mat;
@mixin x-form-layout-theme($theme-config) {
  .x-form-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;

    form {
      display: flex;
      flex-direction: column;
      position: relative;

      mat-form-field:not([class*='col']) {
        width: 100%;
      }
    }

    .form-actions {
      display: flex;
      gap: 10px;
      > * {
        flex: 1;
      }
    }
    .mdc-button[disabled] {
      pointer-events: none;
    }

    .action-buttons {
      display: flex;
      justify-content: right;
    }
  }
}
