@mixin x-content-product-listing-theme($theme-config) {
  .x-content-product-listing {
    display: block;
    overflow: hidden;
    .product-list {
      justify-content: center;
      @include shop-grid-layout(4, 15px);

      @media (min-width: 1400px) {
        @include shop-grid-layout(5, 15px);
      }

      @media (min-width: 1700px) {
        @include shop-grid-layout(6, 15px);
      }

      @include media-breakpoint-down(xxl) {
        @include shop-grid-layout(4, 10px);
      }

      @include media-breakpoint-down(lg) {
        @include shop-grid-layout(3, 10px);
      }

      @include media-breakpoint-down(md) {
        @include shop-grid-layout(2, 10px, 30px);
      }
    }
  }
}
