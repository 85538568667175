@use '@angular/material' as mat;

@mixin x-channel-router-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .x-channel-router {
    x-close-button {
      background: mat.get-color-from-palette(map-get($theme, accent), 100);
    }

    .mat-drawer-container {
      background: none;
      height: 100%;
      overflow: unset;
      color: inherit;

      .mat-drawer-content {
        overflow: unset;
      }
    }

    .mat-drawer {
      width: calc(100% - 40px);
      max-width: 400px;
      overflow-y: visible;
    }

    .footer-actions {
      button {
        @include mat.typography-level($typography, title-case-link);
      }

      .need-help-action {
        color: mat.get-color-from-palette(map-get($theme, primary), 300);
      }
    }

    @include media-breakpoint-down(lg) {
      x-header-layout {
        .header-layout {
          gap: 0;

          .actions-left {
            gap: 0;
            flex: 0 !important;

            fa-icon[icon='bars'] {
              position: relative;
              top: -3px;
            }
          }

          .heading {
            text-align: left !important;
            align-items: flex-start !important;
          }
        }
        .container {
          padding: 0;
        }
      }
    }
  }
}
