@use '@angular/material' as mat;

@mixin x-swiper-gallery-theme($theme-config) {
  $theme: map-get($theme-config, theme);

  .x-swiper-gallery {
    x-youtube-player,
    x-vimeo-player {
      width: 100%;
      aspect-ratio: 16/9;
    }

    video {
      width: 100%;
      aspect-ratio: 1/1;
    }

    .thumbnails {
      margin-top: 10px;
      .swiper-slide-visible {
        cursor: pointer;
        display: flex;
        align-items: center;
        aspect-ratio: 1/1;
        position: relative;

        &.swiper-slide-thumb-active {
          x-media-thumbnail {
            border: 1px solid mat.get-color-from-palette(map-get($theme, primary), 500);
            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 2px solid white;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
