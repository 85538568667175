@mixin x-content-catalog-reel-theme($theme-config) {
  .x-catalog-reel {
    .catalog-reel {
      display: flex;
      justify-content: center;
      gap: 40px;
      width: 900px;
      margin: auto;
      height: 610px;

      .player-col {
        flex: auto;
        aspect-ratio: 9 / 16;
        max-width: 344px;
      }

      .product-list-container {
        position: relative;
        width: 60%;
        height: 100%;

        .product-list {
          display: flex;
          flex-direction: column;
          height: 100%;
          gap: 30px;
          overflow-y: auto;
        }

        .scroll-fade {
          pointer-events: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 45px;
          background: linear-gradient(transparent, white);
          z-index: 1;
        }
      }

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        width: 100%;
        gap: 30px;
        height: auto;

        .player-col {
          max-width: unset;
          width: 100%;
          margin: auto;
        }

        .product-list-container {
          width: 100%;
          height: fit-content;

          .product-list {
            gap: 30px;
          }
        }

        .scroll-fade {
          display: none;
        }
      }
    }
  }
}
