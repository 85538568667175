@use '@angular/material' as mat;

@mixin x-faq-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $background: map-get($theme, 'background');

  .x-faq {
    .container {
      max-width: 800px;
    }
    .mat-expansion-panel {
      border-radius: 0;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(100% - 3.5rem);
        transform: scaleX(1);
        transform-origin: 50% 50%;
        border-bottom: 1px solid #aaa;
        transition: transform 512ms ease;
      }
      &.mat-expanded {
        &:after {
          transform: scaleX(0);
        }
      }
      &-header {
        &-title {
          font-family: map-get($typography, 'font-family');
          position: relative;
          overflow-x: visible;
          font-weight: bold;
          margin-left: 32px;
          padding: 16px 0;

          &:before {
            content: 'Q';
            position: relative;
            left: -16px;
            margin-left: -16px;
            font-weight: normal;
          }
        }
      }
      &-body {
        padding-top: 16px;
        padding-left: 56px;
      }
    }
  }
}
