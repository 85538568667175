@mixin x-cloudinary-player-theme($theme) {
  .x-cloudinary-player {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .video-js {
      background: none;
      height: 100% !important;
      padding: 0 !important;

      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .vjs-poster {
      background: none !important;
    }
  }
}
