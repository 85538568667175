@use '@angular/material' as mat;
@mixin x-variant-availability-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);
  $background: mat.get-color-from-palette(map-get($theme, accent), 200);

  .x-variant-availability {
    display: flex;
    mat-chip {
      background: $background;
    }

    .next-available-date {
      overflow: hidden;
      .chip {
        @include mat.typography-level($typography, display-1);
        background-color: $background;
        border-radius: 10px;
        padding: 5px 7px;
        display: flex;
        gap: 5px;
        overflow: hidden;
        .ellipse {
          flex-shrink: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .no-ellipse {
          flex-grow: 1;
          white-space: nowrap;
        }
      }
    }
  }
}
