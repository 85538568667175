@use '@angular/material' as mat;
@mixin x-order-item-availability-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-order-item-availability {
    .chips {
      overflow: hidden;

      .chip {
        @include mat.typography-level($typography, caption);
        background-color: mat.get-color-from-palette(map-get($theme, accent), 200);
        border-radius: 10px;
        padding: 5px 7px;
        display: flex;
        gap: 5px;
        overflow: hidden;
        .ellipse {
          flex-shrink: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .no-ellipse {
          flex-grow: 1;
          white-space: nowrap;
        }
      }
    }
  }
}
