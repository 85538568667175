@use '@angular/material' as mat;
@mixin x-association-order-item-theme($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .x-association-order-item {
    .x-add-to-cart-button {
      flex: 0;

      .add-to-cart-button-group {
        .no-controls {
          button {
            padding: 5px 15px !important;
          }
        }
      }
    }
  }
}
