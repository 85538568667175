@use '@angular/material' as mat;

@mixin x-mat-tooltip-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  $typography: map-get($theme-config, typography);

  .mat-mdc-tooltip {
    background: mat.get-color-from-palette(map-get($theme, primary), 500);
    border-radius: 0;
    position: relative;
    overflow: visible;
    @include mat.typography-level($typography, display-2);
    padding: 10px;

    .mdc-tooltip__surface {
      background: none;
      color: mat.get-color-from-palette(map-get($theme, primary), '500-contrast');
    }

    &.accent {
      background: mat.get-color-from-palette(map-get($theme, accent), 200);
      .mdc-tooltip__surface {
        color: mat.get-color-from-palette(map-get($theme, accent), '200-contrast');
      }
    }

    &.warn {
      background: mat.get-color-from-palette(map-get($theme, warn), 500);
      .mdc-tooltip__surface {
        color: mat.get-color-from-palette(map-get($theme, warn), '500-contrast');
      }
    }

    &.accent-300 {
      background: mat.get-color-from-palette(map-get($theme, accent), 300);
      .mdc-tooltip__surface {
        color: mat.get-color-from-palette(map-get($theme, accent), '300-contrast');
      }
    }

    &:after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      rotate: 45deg;
      top: 0;
      left: 0;
      background: inherit;
      display: none;
    }
  }

  .mat-mdc-tooltip-panel-below {
    .mat-mdc-tooltip {
      border-radius: 0 0 5px 5px;

      &:after {
        top: -5px;
        left: calc(50% - 5px);
        display: block;
      }
    }
  }

  .mat-mdc-tooltip-panel-above {
    .mat-mdc-tooltip {
      border-radius: 5px 5px 0 0;

      &:after {
        top: auto;
        bottom: -5px;
        left: calc(50% - 5px);
        display: block;
      }
    }
  }

  .mat-mdc-tooltip-panel-after,
  .mat-mdc-tooltip-panel-right {
    .mat-mdc-tooltip {
      border-radius: 0 5px 5px 0;

      &:after {
        top: calc(50% - 5px);
        left: -5px;
        display: block;
      }
    }
  }

  .mat-mdc-tooltip-panel-before,
  .mat-mdc-tooltip-panel-left {
    .mat-mdc-tooltip {
      border-radius: 5px 0 0 5px;

      &:after {
        top: calc(50% - 5px);
        left: auto;
        right: -5px;
        display: block;
      }
    }
  }
}
