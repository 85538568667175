@use '@angular/material' as mat;

@mixin x-snackbar-extensions($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $theme: map-get($theme-config, theme);

  .mat-mdc-snack-bar-container {
    background: map-get($custom-colors, bg-light);
    border-radius: 3px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    padding: 0;
    overflow: hidden;
    justify-content: flex-start;

    .mdc-snackbar__surface {
      min-width: auto;
      width: auto;
      box-shadow: none !important;
    }

    .mdc-snackbar__label {
      color: mat.get-color-from-palette(map-get($theme, accent), '50-contrast');
    }

    .mdc-snackbar__label,
    .mdc-snackbar__surface {
      background: none;
      padding: 0;
    }
  }
}
