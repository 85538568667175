@use '@angular/material' as mat;

@mixin x-shop-color-theme($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  $primary: mat.get-color-from-palette(map-get($theme, primary), 500);
  $accent: mat.get-color-from-palette(map-get($theme, accent), 500);
  $warn: mat.get-color-from-palette(map-get($theme, warn), 500);

  .text-color-primary {
    color: $primary;
  }

  .text-color-warn {
    color: $warn;
  }

  .text-color-accent {
    color: $accent;
  }

  a {
    &.link-color-default {
      color: map-get($custom-colors, text-link);
    }
    &.link-color-primary {
      color: $primary;
    }
    &.link-color-warn {
      color: $warn;
    }
    &.link-color-accent {
      color: $accent;
    }
  }

  svg {
    &.color-light {
      fill: white;
    }
    &.color-dark {
      fill: black;
    }

    &.color-primary {
      fill: $primary;
    }
    &.color-accent {
      fill: $accent;
    }
    &.color-warn {
      fill: $warn;
    }
  }
}
