@use '@angular/material' as mat;

@mixin x-paginator-extensions($theme-config) {
  $custom-colors: map-get(map-get($theme-config, custom), colors);
  $typography: map-get($theme-config, typography);

  .mat-mdc-paginator {
    @include mat.typography-level($typography, display-5);

    .mat-mdc-select-value {
      @include mat.typography-level($typography, display-5);
    }
  }

  .mat-mdc-paginator-page-size-select {
    margin: 0 !important;

    .mdc-text-field {
      border-radius: 5px;
    }
  }

  .mat-mdc-paginator {
    border-top: 1px solid map-get($custom-colors, borders);
  }

  .mat-mdc-paginator-container {
    padding: 0;
  }

  .mat-mdc-paginator-range-label {
    margin: 0 10px !important;
  }

  .mat-mdc-paginator-page-size-label {
    margin: 0 5px !important;
  }
}
