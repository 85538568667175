@use '@angular/material' as mat;

@mixin x-gallery-dialog-theme($theme-config) {
  .x-gallery-dialog {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 10;

    .x-swiper-gallery {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: row-reverse;

      .main {
        flex-grow: 1;
        justify-content: center;
        display: flex;

        swiper-container {
          height: 100%;
          &::part(container) {
            height: 100%;
            aspect-ratio: 1/1;
          }
        }
      }
      .thumbnails {
        padding: 10px 10px 0 10px;
        flex-grow: 0;

        swiper-container {
          height: 100%;

          &::part(container) {
            height: 100%;
          }
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;

        .main {
          width: 100%;
          display: block;

          swiper-slide {
            display: flex;
            align-items: center;
          }

          x-product-media {
            display: flex;
            align-items: center;
            aspect-ratio: unset;
            height: 100%;

            x-media-thumbnail,
            .x-html5-player {
              aspect-ratio: 1/1;
              height: auto;
            }
          }
        }
        .thumbnails {
          padding: 10px;
          width: 100%;
        }
      }
    }

    .dialog-close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      padding: 0.1rem !important;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 5rem;
      margin: 0.5em;
      cursor: pointer;
    }
  }
}
