@use '@angular/material' as mat;

@mixin x-product-zoom-theme($theme-config) {
  .x-product-media.x-product-zoom {
    x-media-thumbnail {
      cursor: zoom-in;
      transition:
        scale 100ms ease,
        translate 100ms ease;
    }

    &.zoomed {
      x-media-thumbnail {
        cursor: zoom-out;
      }
    }
  }
}
