@use '@angular/material' as mat;

@mixin x-button-toggle-group-extensions($theme-config) {
  $theme: map-get($theme-config, theme);
  $custom-colors: map-get(map-get($theme-config, custom), colors);

  .mat-button-toggle-group-appearance-standard {
    border: none;
    border-radius: 0;
  }
  .mat-button-toggle {
    border: 1px solid !important;
    transition: background-color 300ms;
    background-color: white;
    color: map-get($custom-colors, body-color-2);
    &.mat-button-toggle-checked,
    &:hover:not(.mat-button-toggle-disabled) {
      background-color: white;
      border-color: mat.get-color-from-palette(map-get($theme, primary), 500) !important;
    }
    &.mat-button-toggle-disabled {
      color: mat.get-color-from-palette(map-get($theme, accent), 200);
      background: mat.get-color-from-palette(map-get($theme, accent), 50);
      border-color: mat.get-color-from-palette(map-get($theme, accent), 50);
    }
    .mat-button-toggle-button {
      height: 40px;
    }
  }
  .mat-button-toggle-group {
    display: flex;
    gap: 5px;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
  .mat-button-toggle-appearance-standard.cdk-keyboard-focused:not(.mat-button-toggle-disabled)
    .mat-button-toggle-focus-overlay {
    display: none;
  }
}
