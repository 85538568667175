@use '@angular/material' as mat;
@mixin x-checkout-stepper-header-theme($theme-config) {
  .x-checkout-stepper-header {
    @include media-breakpoint-down(lg) {
      padding: 10px;
    }

    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
