@mixin x-order-card-theme($theme-config) {
  .x-order-card {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
    padding: 20px;
    border: 1px solid get-palette-color($theme-config, accent, 200);

    .card-header {
      padding-bottom: 10px;
      border-bottom: 1px solid get-palette-color($theme-config, accent, 200);
    }

    .items {
      @include shop-grid-layout(5, 10px);
    }

    //.success {
    //  color: get-custom-color($theme-config, heavy-success);
    //}
  }
}
